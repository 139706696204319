// import React from "react";
// import "./Footer.css";
// import { Link, useLocation } from "react-router-dom";
// import companyLogo from "../../assets/Group 1000005512 (1) 3.png";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { useGSAP } from "@gsap/react";
// import faceBookIcon from "../../assets/facebookIcon.png";
// import instIcon from "../../assets/instsIcon.png";
// import xIcon from "../../assets/XIcon.png";
// import linkedinIcon from "../../assets/LinkedinIcon.png";
// import youtubeIcon from "../../assets/YouTubeIcon.png";
// gsap.registerPlugin(ScrollTrigger);

// const Footer = () => {
//   const location = useLocation();
//   // useGSAP(() => {
//   //   gsap.from(".footer-unorder-list-container-top", {
//   //     duration: 1.5,
//   //     opacity: 0,
//   //     x: -200,
//   //     stagger: true,
//   //     ease: "power3.inOut ",
//   //     scrollTrigger: {
//   //       trigger: ".footer",
//   //     },
//   //   });
//   //   gsap.from(".footer-unorder-list-container-btm", {
//   //     duration: 1.5,
//   //     opacity: 0,
//   //     y: 200,
//   //     stagger: true,
//   //     ease: "power3.inOut ",
//   //     scrollTrigger: {
//   //       trigger: ".footer",
//   //     },
//   //   });
//   //   gsap.from(".footer-div-btn", {
//   //     duration: 1.5,
//   //     opacity: 0,
//   //     scale: 0.2,
//   //     stagger: true,
//   //     ease: "power3.inOut ",
//   //     scrollTrigger: {
//   //       trigger: ".footer",
//   //     },
//   //   });
//   //   gsap.from(".footer-contact-info", {
//   //     duration: 1.5,
//   //     opacity: 0,
//   //     x: 200,
//   //     stagger: true,
//   //     ease: "power3.inOut ",
//   //     scrollTrigger: {
//   //       trigger: ".footer",
//   //     },
//   //   });
//   // });
//   return (
//     <footer className="footer">
//       <div>
//         <div className="footer-top-container">
//           <div className="company-logo-container">
//             <img src={companyLogo} alt="companyLogo" className="footer-logo" />{" "}
//           </div>
//           <div className="footer_links_main_div">
//           <div className="main_footer_links">
//             <ul className="footer-unorder-list-container-top">
//               <li className="footer-list-item">
//                 <Link to="/">Home</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/Features">Features</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/AboutUs">About Us</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/Clientele">Clientele</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/ContactUs">Contact Us</Link>
//               </li>
//             </ul>
//           </div>
//           <div className="footer_links_main_div1">
//             <ul className="footer-unorder-list-container-btm">
//               <li className="footer-list-item">
//                 <Link to="/blog">Blog</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/TermsConditions">Terms & Conditions</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/PrivacyPolicy">Privacy Policy</Link>
//               </li>
//               <li className="footer-list-item">
//                 <Link to="/careers">Careers</Link>
//                 </li>
//               <li className="footer-list-item">
//                 <Link to="/PartnerProgram">Partner Program</Link>
//               </li>
//             </ul>
//             <div className="footer_Btn_container">
//             <button className="footer-btn">HRMS Demo</button>

//             </div>
//           </div>
//           </div>
//           {/* <div className="footer-div-btn">
//           </div> */}
//           <div>
//             <h3 className="footer-contact-info">
//               <Link to="/ContactUS">Contact Info </Link>
//             </h3>
//           </div>
//         </div>
//         <div className="social-media-icons-container">
//           <img src={faceBookIcon} alt="facebook-icon" />
//           <img src={instIcon} alt="instIcon" />
//           <img src={xIcon} alt="xIcon" />
//           <img src={linkedinIcon} alt="linkedinIcon" />
//           <img src={youtubeIcon} alt="youtubeIcon" />
//         </div>
//         <hr className="footer-hr-line" />
//         <p className="copyrights-para">
//           copyrights by Make CRMs, All rights reserved
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

// ____________________

import React from "react";
import "./Footer.css";
import companyLogo from "../../assets/Group 1000005512 (1) 3.png";
import { Link } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";

const Footer = ({scroll}) => {
  return (
    <div className="footer">
      {/* Top footer */}
      <div className="top_footer">
        <div className="top_footer_1st_div">
          <div className="img">
            <img src={companyLogo} alt="" />
          </div>
          <p>
          Driving business excellence with innovative CRM solutions for a connected future.
          </p>

          <div className="top_footer_buttons">
            <button id="Hrms_btn">HRMS</button>
            <button>BPMS</button>
            <button>PMS</button>
          </div>
          {/* <div className="tridizi">© Copyrights Tridizi Digital Innovation</div> */}
        </div>
        <div className="top_footer_2nd_div">
          <div className="lists">
            <ul className="unordered_lists" onClick={scroll}>
              <li>
                <Link to="/" className="links_to_pages">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/AboutUs" className="links_to_pages">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/Features" className="links_to_pages">
                  Features
                </Link>
              </li>
              <li>
                <Link to="/ContactUS" className="links_to_pages">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/Blog" className="links_to_pages">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/careers" className="links_to_pages">
                  Careers
                </Link>
              </li>
            </ul>
            <ul className="unordered_lists" onClick={scroll}>
              <li>
                <Link to="/PartnerProgram" className="links_to_pages">
                  Partner Program
                </Link>
              </li>
              <li>
                <Link to="/Clientele" className="links_to_pages">
                  Clientele
                </Link>
              </li>
              <li>
                <Link to="/AffiliateProgram" className="links_to_pages">
                  Affiliate Program
                </Link>
              </li>
              <li>
                <Link to="/PrivacyPolicy" className="links_to_pages">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/TermsConditions" className="links_to_pages">
                  Terms & Conditions
                </Link>
              </li>
              
              <button className="get_a_demo">
              <Link  to="https://calendly.com/mamata-makecrms/hrms-demo?month=2024-11" target="blank" className="get_a_demo">Get A Demo </Link></button>
            </ul>
          </div>
          <div className="contact_details">

          <span className="span">Contact Us</span>
            <div className="Social_icons_footer">
              <MdCall className="socialIcon001" />
              <span id="detailes">+91 94936 32575</span>
            </div>
            <div className="Social_icons_footer">
              <IoMdMail className="socialIcon001" />
              <span id="detailes">mamata@makecrms.com</span>
            </div>
            <div className="Social_icons_footer">
              <FaMapMarkerAlt className="socialIcon001" />
              <span id="detailes">
                2nd FloorKhanamet, Hitech </span> <p id="address_span">City RoadHitech City,</p>
                <p id="address_span_1">Hyderabad, 500084India</p>
             
            </div>
            {/* <span className="span">Contact Us</span>
            <p>
              <span className="mail_details">mamata@makecrms.com</span>

              <span className="mail_details">+91 9493632575</span>
              <span className="Address_span">Address :</span>
              <span className="mail_details_address">2nd Floor
                Khanamet,Hitech  </span>
                 <span className="mail_details_address">City Road Hitech City,
                </span>
                <span className="mail_details_address"> Hyderabad, 500084
                India</span> 
            </p> */}
          </div>
        </div>
      </div>

      {/* Bottom Footer */}
      <div className="bottom_footer">
        <span>
          -------------------------------------------------------------------------------------------------------------------------------------------
        </span>
    
        <p> © Copyrights by Make CRMs | TriDizi Digital Innovations</p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useRef, useState } from "react";
import "./PartnerProgram.css";

import Navbar from "./../../components/Navbar/Navbar";
import Footer from "./../../components/Footer/Footer";
import PreFooter from "./../../components/PreFooter/PreFooter";
import gsap from "gsap";
import group21 from "../../assets/Group21.png";
import group22 from "../../assets/Group 22.png";
import group23 from "../../assets/Group 23.png";
import IconsMovement from "../../components/IconsMovement/IconsMovement";
import group27 from "../../assets/Group 27.png";
import group26 from "../../assets/Group 26.png";
import group25 from "../../assets/Group 25.png";
import Scrolling from "../../components/ScrollToTop/Scroll";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import ppheader from "../../assets/image 3014.png"
const PartnerProgram = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // added state variable
  const [isModalOpen2, setIsModalOpen2] = useState(false); // added state

  const buttonRef = useRef(null);
  const outlineRef = useRef(null);
  const navigate = useNavigate();
  const moveToButton = () => {
    const button = buttonRef.current;
    const outline = outlineRef.current;

    gsap.to(outline, {
      x:
        button.offsetLeft -
        outline.offsetLeft +
        (button.offsetWidth - outline.offsetWidth) / 2,
      y:
        button.offsetTop -
        outline.offsetTop +
        (button.offsetHeight - outline.offsetHeight) / 2,
      duration: 0.5,
      ease: "power2.out",
    });
  };
  const resetPosition = () => {
    gsap.to(outlineRef.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: "power2.out",
    });
  };
  const handleNavigate = () => {
    navigate("/AffiliateProgram");
  };
  const handleApply = () => {
    console.log("handleApply called");
    setIsModalOpen(true); // added function to open modal
  };
  const handleCloseModal = () => {
    setIsModalOpen(false); // added function to close modal
  };
  function handleScroll() {
    window.scrollTo({
      top: 900,
      behavior: "smooth",
    });
  }

  return (
    <div className="partner">
      <Navbar />
      <div className="PartnerProgram-bg">
        <div className="PartnerProgram_main_heading_shadow">
          <h1 className="PartnerProgram-main-heading">Partner Program</h1>
        </div>
        <div className="partnerProgram_side">
          <p>PartnerProgram</p>
        </div>
        <div className="PartnerProgram-layer1">
          <div className="text-content-layer-1">
            <h1>
              Build Success <br />
              Through Collaboration
            </h1>
            <p>
              Become a part of the MakeCRMs Partner Program and enhance your
              service offerings with our cutting-edge HRMS solutions. By
              partnering with us, you’ll receive valuable tools, training, and
              ongoing support to help you deliver exceptional value to your
              clients.
            </p>
            <div className="button-container-layer-1-pp">
              <div
                ref={outlineRef}
                className="pp_button_outline"
                onMouseEnter={moveToButton}
                onMouseLeave={resetPosition}
                onClick={handleApply}
              ></div>
              <button
                className="btn-layer-1-left"
                ref={buttonRef}
                onMouseEnter={moveToButton}
                onMouseLeave={resetPosition}
                onClick={handleApply}
              >
                Become an Affiliate
              </button>
              <Link to="/ContactUs" state={{ scrollToForm: true }}>
                <button className="btn-layer-1-right">Request Callback</button>
              </Link>
            </div>
          </div>
          <div className="layer-pp-image-container">
            <img src={ppheader} alt=""/>
          </div>
        </div>
        {/* layer-2 */}
        <div className="pp_bg_image">
          <div className="crm-container layer-2">
            <h1 className="crm-header layer-2">Why Make CRMs?</h1>

            {/* Features Section */}
            <div className="features-box layer-2">
              <div className="feature-item layer-2">
                <div className="icon row-pp layer-2">
                  <img
                    src={group21}
                    alt="group21"
                    className="pp_group_icon_21"
                  />
                  <h3 className="feature-title layer-2">
                    Broadcast Unlimited Offers
                  </h3>
                </div>
                <p className="feature-text layer-2">
                  MakeCRMs offers state-of-the-art HRMS software designed to
                  streamline your HR processes, enhance efficiency, and improve
                  employee engagement. Our technology is built to adapt to the
                  evolving needs of your organization.{" "}
                </p>
              </div>
              <div className="feature-item  layer-2">
                <div className="icon layer-2 row-pp">
                  <img
                    src={group22}
                    alt="group21"
                    className="pp_group_icon_21"
                  />
                  <h3 className="feature-title layer-2">
                    Automate Notifications
                  </h3>
                </div>
                <p className="feature-text layer-2">
                  Our platform prioritizes user experience, making it easy for
                  HR professionals and employees alike to navigate. With
                  intuitive features and seamless functionality, you can focus
                  on what truly matters—your people.
                </p>
              </div>
              <div className="feature-item layer-2">
                <div className="icon row-pp layer-2">
                  <img
                    src={group23}
                    alt="group21"
                    className="pp_group_icon_21"
                  />
                  <h3 className="feature-title layer-2">
                    Multi-Agent Live Chat
                  </h3>
                </div>
                <p className="feature-text layer-2">
                  At MakeCRMs, we pride ourselves on providing exceptional
                  customer service. Our dedicated support team is always ready
                  to assist you, ensuring a smooth implementation and ongoing
                  success with our solutions. Your success is our priority!
                </p>
              </div>
            </div>

            {/* Partner Section */}
            <div className="partner-section layer-2">
              <h2 className="partner-header layer-2">
                Become a Make CRMs Partner
              </h2>
              <div className="partner-benefits layer-2">
                <div className="benefit-item layer-2">
                  <div className="icon layer-2 icon-layer-2">
                    <img src={group27} alt="group27" />
                  </div>
                  <h3 className="benefit-title layer-2">Monthly Payouts</h3>
                  <p className="benefit-text layer-2">
                    Get your Earned Commission Monthly
                  </p>
                </div>
                <div className="benefit-item layer-2">
                  <div className="icon layer-2 icon-layer-2">
                    <img src={group25} alt="group25" />
                  </div>
                  <h3 className="benefit-title layer-2">Onboarding Support</h3>
                  <p className="benefit-text layer-2">
                    Our team helps you onboard Customers Easily
                  </p>
                </div>
                <div className="benefit-item layer-2">
                  <div className="icon layer-2 icon-layer-2">
                    <img src={group26} alt="group26" />
                  </div>
                  <h3 className="benefit-title layer-2">Tracking Dashboard</h3>
                  <p className="benefit-text layer-2">
                    Track all your Conversions via our Affiliate Dashboard
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* layer-3 */}
          <div className="partner-container layer-3-pps">
            <h1 className="partner-header layer-3-pps">
              Become a Partner Today
            </h1>
            <p className="partner-subtext layer-3-pps">
              Signup Now, Share your Link and Start getting Partner Benefits
              Instantly!
            </p>
            <div className="partner-buttons layer-3-pps">
              <button
                className="sign-up-btn layer-3-pps"
                onClick={handleNavigate}
              >
                Sign up Affiliate
              </button>
              <Link
                to="https://calendly.com/mamata-makecrms/hrms-demo?month=2024-11"
                target="blank"
              >
                <button className="demo-btn layer-3-pps">
                  Book a Demo Now
                </button>
              </Link>
            </div>
          </div>
          <IconsMovement />
          <PreFooter />
        </div>
        {isModalOpen && (
          <div className="application-modal_pp">
            <div className="application-modal-content_pp">
              {/* <button
                  className="close-button_pp"
                  onClick={handleCloseModal}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    fontSize: "3vw",
                    cursor: "pointer",
                    position: "absolute",
                    top: "0.5vw",
                    right: "2vw",
                  }}
                important
              >
                &times;
              </button> */}
              <IoCloseSharp className="close-button_pp"                   onClick={handleCloseModal}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    fontSize: "4vw",
                    cursor: "pointer",
                    position: "absolute",
                    top: "1vw",
                    right: "2vw",
                    color:"white"
                  }}/>
              <h2>Become An Affiliate</h2>
              <form>
                <label>Full Name:</label>
                <input
                  type="text"
                  placeholder="Enter your full name"
                  required
                />
                <label>Email:</label>
                <input type="email" placeholder="Enter your email" required />
                <label>Phone Number:</label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  required
                />
                <p>
                  Be part of the Make CRMs Partner Program – Building Success
                  Together!
                </p>
                {/* <label>Resume:</label> */}
                {/* <input type="file" required /> */}
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
      <Scrolling />
    </div>
  );
};

export default PartnerProgram;

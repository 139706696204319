import React, { useRef } from "react";
import "./IconsMovement.css";
import Icon1 from "../../assets/mainlogo (1) 1.png";
import Icon2 from "../../assets/logo 1.png";
import Icon3 from "../../assets/logo-goodtobuy 5.png";
import Icon4 from "../../assets/image 2917.png";
import Icon5 from "../../assets/logo...... 1.png";

const ClientsCardsHalf = [
  {
    id: 1,
    image: Icon1,
  },
  {
    id: 2,
    image: Icon2,
  },
  {
    id: 3,
    image: Icon3,
  },
  {
    id: 4,
    image: Icon4,
  },
  {
    id: 5,
    image: Icon5,
  },
];
function IconsMovement() {
  const clientsRefTop = useRef(null);
  const clientsRefBottom = useRef(null);

  const handleMouseEnter = () => {
    if (clientsRefTop.current && clientsRefBottom.current) {
      clientsRefTop.current.style.animationPlayState = "paused";
      clientsRefBottom.current.style.animationPlayState = "paused";
    }
  };

  const handleMouseLeave = () => {
    if (clientsRefTop.current && clientsRefBottom.current) {
      clientsRefTop.current.style.animationPlayState = "running";
      clientsRefBottom.current.style.animationPlayState = "running";
    }
  };
  return (
    <>
      <hr className="icons-hr-line-top" />
      {/* <div> */}
      <section
        className="clients"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="Client-Cards-MMM" ref={clientsRefTop}>
          {ClientsCardsHalf.map((cards, indx) => {
            return (
              <div className="client-section-container" key={indx}>
                <div className="client-container-box">
                  <img
                    className="claint-images-1"
                    src={cards.image}
                    alt="img-11"
                    id={`clint_image_${indx}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="Client-Cards-MMM" ref={clientsRefBottom}>
          {ClientsCardsHalf.map((cards, indx) => {
            return (
              <div className="client-section-container" key={indx}>
                <div className="client-container-box">
                  <img
                    className="claint-images-1"
                    src={cards.image}
                    alt="img-11"
                    id={`clint_image_${indx}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* </div> */}
      <hr className="icons-hr-line-btm" />
    </>
  );
}

export default IconsMovement;

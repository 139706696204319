import React, { useEffect } from "react";
import globeImg from "../../assets/future_image_2.png";
import globe_2 from "../../assets/future_img_1.png";
import globe_3 from "../../assets/future_image_3.png";
import globe_4 from "../../assets/future_image_4.png";
import "./futureanimation.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const FutureAnimation = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    //first auto animation
    const tl = gsap.timeline();

    // Define the animation sequence
    tl.fromTo(
      ".animation-features-1st-page-title-1",
      { y: -200, opacity: 0 },
      { y: 0, duration: 1, opacity: 1 }
    )
      .fromTo(
        ".animation-layer-1-one-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 1, opacity: 1 }
      )
      .fromTo(
        ".animation-features-1st-page-title-2",
        { y: -200, opacity: 0 },
        { y: 0, duration: 1, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-1-two-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 1, opacity: 1 }
      )
      .fromTo(
        ".animation-layerOne-center-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 1, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-1-three-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 1, opacity: 1 }
      );

    //   _________________________________________________
    //second scrolling animation

    const second = gsap.timeline({
      scrollTrigger: {
        trigger: ".animation_second_image",
        start: "top center",
        end: "80%",
        // markers: true,
        scrub: 3,
      },
    });

    second
      .fromTo(
        ".animation-layer-2-one-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-2-one-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-2-two-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-2-two-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-2-three-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-2-three-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      );

    //   _____________________________________

    //third scrolling animation

    const third = gsap.timeline({
      scrollTrigger: {
        trigger: ".third_animation_container",
        start: "top center",
        end: "80%",
        // markers: true,
        scrub: 3,
      },
    });

    third
      .fromTo(
        ".animation-features-third-page-title-1",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer3-top-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-features-third-page-title-2",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer3-top-2-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-features-third-page-title-3",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer-3-bottom-p_para-color",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      );

      //fourth animation function

    const fourth = gsap.timeline({
      scrollTrigger: {
        trigger: ".fourth_container_animation",
        start: "top center",
        end: "80%",
        // markers: true,
        scrub: 3,
      },
    });

    fourth.fromTo(
        ".animation-layer4-top-1-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer4-top-1-p",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer4-left-2-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer4-left-2-p",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer4-rigth-3-h",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      )
      .fromTo(
        ".animation-layer4-rigth-4-p",
        { y: -200, opacity: 0 },
        { y: 0, duration: 5, opacity: 1 }
      );

    // clean up function
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      second.kill();
    };
  }, []);

  return (
    <div className="animation-hrms-page-container">
      {/* Your existing pages go here */}
      <div className="animation-page-first-page">
        <div className="image_container">
          <img src={globeImg} alt="World Globe" id="animation-globe_1" />
        </div>
        <div className="animation-top-section">
          <div className="animation-title-top-feature-page">
            <h3
              // ref={''}
              className="animation-features-1st-page-title-1"
            >
              Employee Management System
            </h3>
            <p className="animation-layer-1-one-p_para-color">
              Streamline employee data management, tracking performance, and
              simplifying HR processes.
            </p>
          </div>
          <div className="animation-img-container"></div>
        

          <div className="animation-title-center-feature-page">
            <h3 className="animation-features-1st-page-title-2">
              Payroll Management
            </h3>
            <p className="animation-layer-1-two-p_para-color">
              Automate payroll processing, tax calculations, and salary
              disbursement with seamless precision.
            </p>
          </div>

          <div className="animation-middle-section">
            <h3 className="animation-layerOne-center-h">
              Attendance Management
            </h3>
            <p className="animation-layer-1-three-p_para-color">
              Monitor employee attendance, leave, and work hours effortlessly
              for accurate tracking and reporting.
            </p>
          </div>
        </div>
      </div>
      <div className="animation-styled-hr"></div>

      {/* Second Page */}
      <div>
        <div className="animation_second_image">
          <img src={globe_2} alt="World Globe" id="animation-globe_2" />
        </div>
        <div className="animation-page_second-page " id="animation-pagetwo">
          <div className="animation-middle-section-bottom">
            <h3 className="animation-layer-2-one-h">Employee Self Service</h3>
            <p className="animation-layer-2-one-p_para-color ">
              Empower employees to access personal data, policies, and manage
              their profiles independently.
            </p>
          </div>
          <div className="animation-bottom-section">
            <div className="animation-title-bottom">
              <h3 className="animation-layer-2-two-h">
                Work Report Management
              </h3>
              <p className="animation-layer-2-two-p_para-color">
                Simplify work report submission, tracking, and performance
                reviews, integrated with project management.
              </p>
            </div>
            <div className="animation-img-container"></div>
            <div className="animation-title-bottom-second-layer">
              <h3 className="animation-layer-2-three-h">Leave Management</h3>
              <p className="animation-layer-2-three-p_para-color">
                Efficiently manage leave requests, approvals, and balances with
                a user-friendly, automated system.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="animation-styled-hr"></div>

      {/* Third Page */}
      <div className="animation-page first-page" id="pagethree">
        <div className="third_animation_container">
          <img src={globe_3} alt="World Globe" id="animation-globe_3" />
        </div>
        <div className="animation-top-third-section">
          <div className="animation-title-third-page-feature-page">
            <h3 className="animation-features-third-page-title-1">
              Training & Learning
            </h3>
            <p className="animation-layer3-top-p_para-color">
              Facilitate employee development through customizable training
              programs and learning modules.
            </p>
          </div>
          <div className="animation-img-container"></div>
          <div className="animation-title-third-page-feature-page">
            <h3 className="animation-features-third-page-title-2">
              Organization Structure
            </h3>
            <p className="animation-layer3-top-2-p_para-color">
              Visualize and manage your company's hierarchy with an intuitive
              organizational structure tool.
            </p>
          </div>

          <div className="animation-title-third-page-feature-page">
            <h3 className="animation-features-third-page-title-3">
              Task Management
            </h3>
            <p className="animation-layer-3-bottom-p_para-color">
              Streamline task assignment, tracking, and completion for enhanced
              productivity and collaboration.
            </p>
          </div>
        </div>
      </div>
      <div className="animation-styled-hr"></div>

      {/* Fourth Page */}
      <div className="animation-page second-page" id="pagefour">
        <div className="fourth_container_animation">
        <img src={globe_4} alt="World Globe" id="animation-globe_4" /> 
        </div>
        <div className="animation-bottom-section">
          <div className="animation-middle-section-bottom">
            <h3 className="animation-layer4-top-1-h">Employee Self Service</h3>
            <p className="animation-layer4-top-1-p">
              Empower employees to access personal data, policies, and manage
              their profiles independently.
            </p>
          </div>

          <div className="animation-title-bottom">
            <h3 className="animation-layer4-left-2-h">
              Work Report Management
            </h3>
            <p className="animation-layer4-left-2-p">
              Simplify work report submission, tracking, and performance
              reviews, integrated with project management.
            </p>
          </div>
          {/* <div className="animation-img-container"></div> */}
          <div className="animation-title-bottom-second-layer_bottom">
            <h3 className="animation-layer4-rigth-3-h">Leave Management</h3>
            <p className="animation-layer4-rigth-4-p">
              Efficiently manage leave requests, approvals, and balances with a
              user-friendly, automated system.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureAnimation;

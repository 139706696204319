import { FaChalkboardTeacher, FaRegCalendarCheck } from "react-icons/fa";
import "./fancomponent.css";
import teamWork from "../../assets/WhatsApp Image 2024-11-19 at 7.23.54 PM.jpeg"
import smartApproach from "../../assets/image 3007.png"
import vision from "../../assets/image 3008.png"
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";
const FanComponent = () => {
  return (
    <div className="fan_main_container">
      <div className="top_container">
        <div className="work_left_container">
          <h2>Team Work</h2>
          <img src={teamWork} alt="" />
          <p>
            Collaborating effectively to achieve common goals, we believe that
            success is built on strong teamwork and shared responsibility.
          </p>
        </div>

        <div className="work_right_container">
          <h2>Smart Approach</h2>
          <img src={smartApproach} alt="" />

          <p>
          Applying creativity and strategy, we emphasize efficiency and innovation to solve challenges and achieve outcomes with precision and impact.          </p>
        </div>
      </div>

      <div>
        <div className="medial_container">
          <p className="cricle_1">
            <p>
        
            <TbDeviceDesktopAnalytics className="icons_1" />


            </p>
          </p>
            <p className="line_1">
                <p className="doted_lines"></p>
            </p>
          <p className="cricle_2">
          <p>
          <FaRegCalendarCheck className="icons_2"  />

          
          </p>
          </p>
          <p className="line_2">
          <p className="doted_lines_2"></p>
          </p>
          <p className="cricle_3">
             <p>
            
             <MdManageAccounts className="icons_3" />
             </p>
          </p>
        </div>
      </div>
      <div>
        <div className="last_container">
          <div className="bottom_container">
            <h2>Vision</h2>
            <img src={vision} alt="" />
            <p>
            Guided by a clear and forward-looking perspective, we strive to shape a better future, turning possibilities into impactful realities.            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FanComponent;

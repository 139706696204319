import React, { useEffect, useRef, useState } from "react";
import "./AboutUs.css";
import Navbar from "../../components/Navbar/Navbar";
import fanImg from "../../assets/fan-img.png";
import Sshape from "../../assets/Vector 487.png";
import layerimgAbt from "../../assets/image 3003.png";
import layerimgAbt2 from "../../assets/image 3002.png";
import globeImg from "../../assets/globe FEATURES.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { useGSAP } from "@gsap/react";
import logocompany from "../../assets/logo-company.png";
import Footer from "../../components/Footer/Footer";
import PreFooter from "./../../components/PreFooter/PreFooter";
import halfGlobe from "../../assets/halfGlobe.png";
import Icon1 from "../../assets/mainlogo (1) 1.png";
import Icon2 from "../../assets/logo 1.png";
import Icon3 from "../../assets/logo-goodtobuy 5.png";
import Icon4 from "../../assets/image 2917.png";
import Icon5 from "../../assets/logo...... 1.png";
import IconsMovement from "../../components/IconsMovement/IconsMovement";
import Scrolling from "../../components/ScrollToTop/Scroll";
import FanComponent from "../../components/about/fancomponent";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import {
  FaFileSignature,
  FaFunnelDollar,
  FaHandHoldingUsd,
  FaPoll,
  FaRegCalendarCheck,
} from "react-icons/fa";
import {
  MdContactPhone,
  MdManageAccounts,
  MdOutlineBarChart,
} from "react-icons/md";
import { PiUsersThreeFill } from "react-icons/pi";
import { CgNotes } from "react-icons/cg";
import {
  BsCalendarMonthFill,
  BsFillCalendarCheckFill,
  BsFillPersonLinesFill,
  BsFillPersonVcardFill,
} from "react-icons/bs";
import { BiSolidReport, BiTaskX } from "react-icons/bi";
import { IoCalendarNumberSharp, IoCalendarSharp } from "react-icons/io5";
import { LiaClipboardListSolid } from "react-icons/lia";
import { GrDocumentPerformance } from "react-icons/gr";
import { RiExchangeDollarLine, RiSurveyFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import {
  FaFileCircleCheck,
  FaFileCircleQuestion,
  FaPersonWalking,
  FaPersonWalkingDashedLineArrowRight,
  FaSackDollar,
} from "react-icons/fa6";
import { IoMdAnalytics } from "react-icons/io";
import { HiClipboardDocumentList } from "react-icons/hi2";
gsap.registerPlugin(MotionPathPlugin);

const ClientsCardsHalf = [
  {
    id: 1,
    image: Icon1,
  },
  {
    id: 2,
    image: Icon2,
  },
  {
    id: 3,
    image: Icon3,
  },
  {
    id: 4,
    image: Icon4,
  },
  {
    id: 5,
    image: Icon5,
  },
];
const AboutUs = () => {
  const clientsRefTop = useRef(null);
  const clientsRefBottom = useRef(null);

  const handleMouseEnter = () => {
    if (clientsRefTop.current && clientsRefBottom.current) {
      clientsRefTop.current.style.animationPlayState = "paused";
      clientsRefBottom.current.style.animationPlayState = "paused";
    }
  };

  const handleMouseLeave = () => {
    if (clientsRefTop.current && clientsRefBottom.current) {
      clientsRefTop.current.style.animationPlayState = "running";
      clientsRefBottom.current.style.animationPlayState = "running";
    }
  };
  // useGSAP(() => {
  //   gsap.to(".globe-img-1", {
  //     duration: 20, // Time for animation
  //     repeat: -1, // Infinite repeat
  //     stagger: 1,
  //     // yoyo: true, // Go back to starting point
  //     ease: "power1.inOut", // Easing function for smoothness
  //     motionPath: {
  //       path: "#globePath", // The ID of the path you want to follow
  //       align: "#globePath",
  //       alignOrigin: [0.5, 0.5], // Center the image on the path
  //       // autoRotate: true, // Ensure proper rotation
  //       // start: 0, // Ensure the animation starts from the correct position
  //       // end: 1, // Ends after one full path traversal
  //     },
  //   });
  // });
  return (
    <div className="aboutUs">
      <Navbar />
      <div className="about-bg">
        <div className="about-layer-1">
          <FanComponent />
        </div>
        {/* <img
          src={logocompany}
          alt="Company Logo"
          className="img-logo-company"
        /> */}
        <div className="about-layer-2">
          <img src={Sshape} alt="Sshape" className="s-image" />
          <img src={layerimgAbt} alt="layerimgAbt" className="layerimg-Abt" />
          <section className="abt-layer2-right-content">
            <p>
              At MakeCRMs, we believe that teamwork is the cornerstone of
              success. Our dedicated team combines diverse expertise in HR
              management and technology to create innovative HRMS solutions that
              empower organizations to thrive. We understand that achieving
              goals requires collaboration, communication, and a shared vision.
              {/* </p>
            <p  id="p_content"> */}
              <br /> <br />
              Our mission is to streamline HR processes, enhance employee
              engagement, and drive productivity through our user-friendly
              software. With a commitment to excellence and a passion for
              problem-solving, we work hand-in-hand with our clients to ensure
              they receive the best support and solutions tailored to their
              unique needs.
            </p>
          </section>
          <img
            src={layerimgAbt2}
            alt="layerimgAbt2"
            className="layerimg-Abt2"
          />
          <section className="abt-layer2-left-content">
            <p>
              Together, we’re not just building software; we’re fostering a
              community of success where every member contributes to a brighter
              future for their organization. Join us on this journey, and let’s
              achieve greatness together!
              {/* </p>{" "}
            <p style={{ marginTop: "3vw" }}>
              {" "} */}
              <br /> <br />
              Our team is composed of seasoned professionals who bring a wealth
              of experience in human resources, software development, and
              customer service. We prioritize understanding our clients'
              challenges and objectives, allowing us to tailor our solutions to
              fit their specific needs. This collaborative approach ensures that
              we not only meet but exceed expectations.
            </p>
          </section>
          {/* <img
            src={globeImg}
            alt="globe-img"
            className="globe-img-1"
            style={{
              position: "relative",
            }}
          /> */}
          <div className="main_icons_div">
            <div className="globe-img-1">
              <TbDeviceDesktopAnalytics style={{ color: "gray" }} id="icon_1" />
            </div>
            <div className="about_icon2">
              <FaRegCalendarCheck id="icon_2" />
            </div>
            <div className="about_icon3">
              <MdManageAccounts id="icon_1" />
            </div>
            <div className="about_icon4">
              <CgNotes id="icon" />
            </div>
            <div className="about_icon5">
              <BsFillCalendarCheckFill id="icon" />
            </div>
            <div className="about_icon6">
              <BiSolidReport id="icon" />
            </div>
            <div className="about_icon7">
              <IoCalendarNumberSharp id="icon" />
            </div>
            <div className="about_icon8">
              <LiaClipboardListSolid id="icon_1" />
            </div>
            <div className="about_icon9">
              <BsFillPersonVcardFill id="icon_1" />
            </div>
            <div className="about_icon10">
              <FaFunnelDollar id="icon" />
            </div>
            <div className="about_icon11">
              <GrDocumentPerformance id="icon" />
            </div>
            <div className="about_icon12">
              <RiExchangeDollarLine id="icon_1" />
            </div>
            <div className="about_icon13">
              <FaHandHoldingUsd id="icon_1" />
            </div>
            <div className="about_icon14">
              <MdOutlineBarChart id="icon_1" />
            </div>
            <div className="about_icon15">
              <ImProfile id="icon" />
            </div>
            <div className="about_icon16">
              <FaPersonWalkingDashedLineArrowRight id="icon" />
            </div>
            {/* <div className="about_icon17">
              <FaPersonWalking fontSize="5vw" />
            </div> */}
            <div></div>
            <div>
              {/* 
            
            <FaPoll fontSize="5vw" />
            <RiSurveyFill fontSize="5vw" />
            <IoMdAnalytics fontSize="5vw" />
            <FaFileSignature fontSize="5vw" />
            <BiTaskX fontSize="5vw" />
            <FaFileCircleQuestion fontSize="5vw" />
            <FaFileCircleCheck fontSize="5vw" />
            <IoCalendarSharp fontSize="5vw" />
            <BsFillPersonLinesFill fontSize="5vw" />
            <FaSackDollar fontSize="5vw" />
            <HiClipboardDocumentList fontSize="5vw" />
            <MdContactPhone fontSize="5vw" /> */}
            </div>
          </div>

          <svg className="my-svg" viewBox="0 0 1000 1000">
            <defs>
              <radialGradient
                gradientUnits="userSpaceOnUse"
                cx="319.52"
                cy="108.7311"
                r="66.9971"
                id="gradient-0"
                gradientTransform="matrix(1.20392513, 0.01346725, -0.00763025, 1.41760397, -226.35512331, -25.59554079)"
              >
                <stop offset="0" style={{ stopColor: "rgb(126, 250, 250)" }} />
                <stop offset="1" style={{ stopColor: "rgb(94, 147, 245)" }} />
              </radialGradient>
            </defs>
            <path
              id="globePath"
              className="path"
              style={{
                fill: "none",
                stroke: "rgb(236, 30, 93)",
                strokeWidth: 0,
              }}
              // d="M 100 500 C 300 100, 700 100, 900 500 S 1500 900, 1000 500"
              d="M 1006.60 39.636 C 200 42.68 360.736 7.500 173.561 50.20 C 85.150 70.600 32.208 270.919 119.975 449.555 C 144.759 500 534.966 500 616.549 500 C 803.316 500 859.59 527.938 879.146 561.255 C 894.387 587.22 858.986 819.087 848.835 955.816 C 845.115 1005.924 309.438 984.742 242.384 983.078 C -5.81 976.917 73.098 983.637 0 979.065"
            />
          </svg>
        </div>
      </div>
      <div className="half-globe-container">
        <img src={halfGlobe} alt="halfglobe" className="half-globe" />
        <h1>We are Serving WORLD WIDE </h1>
      </div>

      <div>
        <IconsMovement />
      </div>

      <PreFooter />
      <Footer />
      <Scrolling />
    </div>
  );
};

export default AboutUs;

import React, { useState, useEffect, useRef } from "react";
import FeaturesCarousel from "../../components/FeaturesCarousel/FeaturesCarousel.js";
import { GoArrowUpRight } from "react-icons/go";

import logo from "../../assets/Group 1000005512 (1) 3.png";
import img1 from "../../assets/image 2903.png";
import img2 from "../../assets/image 2902.png";

import claintImg1 from "../../assets/image 8.png";
import claintImg2 from "../../assets/image 9.png";
import claintImg3 from "../../assets/image 10.png";
import claintImg4 from "../../assets/client_home_3.jpg";
import claintImg5 from "../../assets/credit-client_1.png";
import polygonImg from "../../assets/Polygon 52.png";
import ArrayImg from "../../assets/Arrow 49.png";
import "./Home.css";
import Footer from "../../components/Footer/Footer.js";
import vector479 from "../../assets/Vector 479.png";
import CanvasComponent from "./CanvasComponent.js";
import Navbar from "../../components/Navbar/Navbar.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import IconsMovement from "../../components/IconsMovement/IconsMovement.js";
import PreFooter from "../../components/PreFooter/PreFooter.js";
import { useGSAP } from "@gsap/react";
import Scrolling from "../../components/ScrollToTop/Scroll.js";
import hrms_image from "../../assets/hrms_images.png";
import m_image from "../../assets/m_image.png";
import m2_image from "../../assets/m2_image.png";

import group27 from "../../assets/Group 27.png";
import group26 from "../../assets/Group 26.png";
import group25 from "../../assets/Group 25.png";

// import { gsap } from "gsap";

const clients = [
  {
    id:1,
    name: "Samir, Ipack Delivery",
    description:
      "The centralized employee database has made it incredibly easy for us to access vital information. Plus, the payroll management feature saves us hours every month. We highly recommend MakeCRMs!",
    img: claintImg1,
  },
  {
    id:2,
    name: "Mark, OneLenz",
    description:
      "The performance management tools have truly revolutionized our approach to employee feedback. Our staff feels more engaged and motivated, and we’re seeing great improvements in overall productivity.",
    img: claintImg2,
  },
  {
    id:3,
    name: "Seshanadh, Erotech Solutions",
    description:
      "The implementation of MakeCRMs was seamless. The support team was beneficial, and we've seen a marked improvement in our employee management since adopting the system.",
    img: claintImg3,
  },
  {
    id:4,
    name: " Subhash, SSR Software Trainings ",
    description:
      "The analytics features in MakeCRMs have given us invaluable insights into our workforce. We're making data-driven decisions that are positively impacting our company culture.",
    img: claintImg4,
  },
  {
    id:5,
    name: "Seshanadh, Erotech Solutions",
    description:
      "The implementation of MakeCRMs was seamless. The support team was beneficial, and we've seen a marked improvement in our employee management since adopting the system.",
    img: claintImg5,
  },
];
gsap.registerPlugin(ScrollTrigger);
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeLink, setActiveLink] = useState("");
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const buttonRefAft = useRef(null);
  const outlineRefAft = useRef(null);
  // const [sizes, setSizes] = useState({
  //   verticalLengthVW: 0,
  //   verticalWidthVW: 0,
  //   horizontalLengthVW: 0,
  //   horizontalWidthVW: 0,
  // });
  // const calculateVWValues = () => {
  //   const viewportWidth = window.innerWidth;
  //   const viewportHeight = window.innerHeight;

  //   setSizes({
  //     verticalLengthVW: (50 / 100) * viewportWidth, // 50vw
  //     verticalWidthVW: (30 / 100) * viewportWidth, // 30vw
  //     horizontalLengthVW: (50 / 100) * viewportWidth, // 70vw
  //     horizontalWidthVW: (25 / 100) * viewportHeight, // 25vh
  //   });
  // };
  // useEffect(() => {
  //   calculateVWValues(); // Initial calculation on mount
  //   window.addEventListener("resize", calculateVWValues); // Recalculate on window resize

  //   return () => {
  //     window.removeEventListener("resize", calculateVWValues);
  //   };
  // }, []);

  const vw = (percentage) => (window.innerWidth * percentage) / 100;

  // const sizes = {
  //   verticalLengthVW: vw(38), // 30% of viewport width
  //   verticalWidthVW: vw(23),  // 10% of viewport width
  //   horizontalLengthVW: vw(50), // 40% of viewport width
  //   horizontalWidthVW: vw(17),  // 15% of viewport width
  // };
  const [sizes, setSizes] = useState({
    verticalLengthVW: vw(38),
    verticalWidthVW: vw(23),
    horizontalLengthVW: vw(50),
    horizontalWidthVW: vw(17),
  });
  useEffect(() => {
    const handleScroll = () => {
      setIsHovered1(false); // Reset hover state when scrolling
      setIsHovered2(false); // Reset hover state for second container
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [scrollEnabled, setScrollEnabled] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % clients.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // useGSAP(() => {
  //   let contents = gsap.utils.toArray("#horizontal .content");
  //   gsap.to(contents, {
  //     xPercent: -100 * (contents.length - 1),
  //     scrollTrigger: {
  //       trigger: ".layer2",
  //       pin: true,
  //       scrub: 1,
  //       // snap: 1 / (contents.length - 1),
  //       end: () => "+=" + document.querySelector("#horizontal").offsetWidth,
  //     },
  //   });
  // });

  useGSAP(() => {
    const contents = gsap.utils.toArray("#horizontal .content");
    const isMobile = window.matchMedia("(max-width: 768px)").matches; // Check for mobile view (adjust breakpoint as needed)
  
    if (isMobile) {
      // Auto-scroll animation for mobile view
      const autoScroll = gsap.timeline({ repeat: -1, repeatDelay: 1 });
      autoScroll.to(contents, {
        xPercent: -100 * (contents.length - 1),
        duration: 10, // Adjust duration for auto-scroll speed
        ease: "none",
      });
    } else {
      // Normal scroll-based animation for desktop view
      gsap.to(contents, {
        xPercent: -100 * (contents.length - 1),
        scrollTrigger: {
          trigger: ".layer2",
          pin: true,
          scrub: 1,
          // snap: 1 / (contents.length - 1),
          end: () => "+=" + document.querySelector("#horizontal").offsetWidth,
        },
      });
    }
  });
  

  const handleNavClick = (link) => {
    setActiveLink(link); // Set the clicked link as the active link
  };
  const getVisibleIndices = () => {
    const total = clients.length;
    const indices = [];
    for (let i = -2; i <= 2; i++) {
      indices.push((currentIndex + i + total) % total); // circular wrap-around
    }
    return indices;
  };

  const visibleIndices = getVisibleIndices();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 720) {
        // Mobile view sizes
        setSizes({
          verticalLengthVW: vw(70), // Adjust for mobile, can be smaller
          verticalWidthVW: vw(40),
          horizontalLengthVW: vw(80),
          horizontalWidthVW: vw(30),
        });
      } else {
        // Desktop view sizes
        setSizes({
          verticalLengthVW: vw(38),
          verticalWidthVW: vw(23),
          horizontalLengthVW: vw(50),
          horizontalWidthVW: vw(17),
        });
      }
    };

    // Attach event listener to window resize
    window.addEventListener("resize", handleResize);

    // Initial check for the current screen size
    handleResize();

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const moveToButton = () => {
  //   const button = buttonRefAft.current;
  //   const outline = outlineRefAft.current;

  //   gsap.to(outline, {
  //     x:
  //       button.offsetLeft -
  //       outline.offsetLeft +
  //       (button.offsetWidth - outline.offsetWidth) / 2,
  //     y:
  //       button.offsetTop -
  //       outline.offsetTop +
  //       (button.offsetHeight - outline.offsetHeight) / 2,
  //     duration: 0.5,
  //     ease: "power2.out",
  //   });
  // };

  const resetPosition = () => {
    gsap.to(outlineRefAft.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: "power2.out",
    });
  };

  return (
    <>
      <Navbar />
      <div className="main_container">
        <div className="layer1">
          <div className="layer-1-home-text">
            <img src={hrms_image} />
            {/* <p className="home-layer-p">Human Resource Management System</p> */}
          </div>
        </div>
        <div className="layer2">
          <img src={img1} alt="img1" className="img1-home-layer-1" />
          {/* features data */}
          <div id="horizontal">
            <div class="content">
              <div class="col_1">
                <h1>features-1</h1>
                <div class="row">
                  <div className="row-inside-row-home" id="row_inside_div_1">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" id="arrow_icon" />
                    <p> Employee Management System</p>
                  </div>
                  <div className="row-inside-row-home" id="row_inside_div_2">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon_1" />
                    <p id="payroll">Payroll Management</p>
                  </div>
                  <div className="row-inside-row-home" id="row_inside_div_3">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p>Attendance Management</p>
                  </div>
                </div>
                <hr className="features-hr-line" />
              </div>
            </div>
            <div class="content">
              <div class="col_2">
                <h1>features-2</h1>
                <div class="row" id="row_2">
                  <div className="row-inside-row-home">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="employee">Employee Self Service</p>
                  </div>

                  <div className="row-inside-row-home" id="row_inside_div_5">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p>Work Report Management</p>
                  </div>
                  <div className="row-inside-row-home" id="row_inside_div_6">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="leave"> Leave Management</p>
                  </div>
                </div>
                <hr className="features-hr-line" />
              </div>
            </div>
            <div class="content">
              <div class="col_3">
                <h1>features-3</h1>
                <div class="row" id="row_3">
                  <div className="row-inside-row-home">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="leave">Training & Learning</p>
                  </div>

                  <div className="row-inside-row-home" id="row_inside_div_8">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="leave"> Organization Structure</p>
                  </div>
                  <div className="row-inside-row-home" id="row_inside_div_9">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="leave">Task Management</p>
                  </div>
                </div>
                <hr className="features-hr-line" />
              </div>
            </div>
            <div class="content">
              <div class="col_4">
                <h1>features-4</h1>
                <div class="row" id="row_4">
                  <div className="row-inside-row-home">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="leave">Employee Self Service</p>
                  </div>

                  <div className="row-inside-row-home" id="row_inside_div_11">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p>Work Report Management</p>
                  </div>
                  <div className="row-inside-row-home" id="row_inside_div_12">
                    {/* <img src={ArrayImg} alt="" className="arrow-img-home" /> */}
                    <GoArrowUpRight className="arrow-react-icon" />
                    <p id="leave">Leave Management</p>
                  </div>
                </div>
                <hr className="features-hr-line" />
              </div>
            </div>
          </div>
          <img src={img2} alt="img2" className="layer-2-home-img" />
        </div>
        <div className="layer_container_3">
        <div className="layer3-home" style={{ height: "100vh" }}>
          <div className="layer3_sub1">
            <div className="layer3_img">
              <img src={m_image} />
              {/* <CanvasComponent
                verticalLength={sizes.verticalLengthVW}
                verticalWidth={sizes.verticalWidthVW}
                horizontalLength={sizes.horizontalLengthVW}
                horizontalWidth={sizes.horizontalWidthVW}
                // verticalLength={550} // Length of the vertical part
                // verticalWidth={300} // Width of the vertical part
                // horizontalLength={700} // Length of the horizontal part
                // horizontalWidth={250}
                flip={false} // Regular L-shape
              /> */}
            </div>
            <div className="layer3_content">
              <p>
                <span>
                  {" "}
                  <b>Make CRM's </b>{" "}
                </span>{" "}
                is your partner in transforming human resource management. Our
                platform is designed to streamline HR processes, enhance
                employee engagement, and boost productivity, all while
                simplifying your day-to-day operations.
                <br /> <br></br> With MakeCRMs, you can manage every aspect of
                human resources in one place. Our comprehensive software covers
                recruitment, onboarding, payroll management, and performance
                evaluation, allowing your team to focus on what truly
                matters—your people.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="layer4_shadow"></div> */}
        <div className="layer4">
          <div className="layer4_content">
            <p>
              <span>
                {" "}
                <b> Make CRM's </b>{" "}
              </span>{" "}
              Experience the benefits of seamless recruitment, efficient
              onboarding, a centralized employee database, and automated payroll
              management. Our performance management tools foster a culture of
              continuous improvement, making it easier to set and achieve
              organizational goals. <br /> <br></br>
              Choose MakeCRMs for a user-friendly interface, customizable
              features, and dedicated customer support. Our software not only
              saves you time and resources but also empowers you to make
              data-driven decisions for better workforce management.
            </p>
          </div>
          <div className="layer4_img">
            <img src={m2_image} />
            {/* <CanvasComponent
              // verticalLength={550} // Length of the vertical part
              // verticalWidth={300} // Width of the vertical part
              // horizontalLength={700} // Length of the horizontal part
              // horizontalWidth={250}
              verticalLength={sizes.verticalLengthVW}
              verticalWidth={sizes.verticalWidthVW}
              horizontalLength={sizes.horizontalLengthVW}
              horizontalWidth={sizes.horizontalWidthVW}
              flip={true} // Flipped L-shape
            /> */}
          </div>
        </div>
        </div>
        <div className="parner_container">
          <div className="partner-section layer-2" id="partner_container">
            <h2 className="partner-header layer-2">Become a Partner</h2>
            <div className="partner-benefits layer-2">
              <div className="benefit-item layer-2">
                <div className="icon layer-2 icon-layer-2">
                  <img src={group27} alt="group27" />
                </div>
                <h3 className="benefit-title layer-2">Monthly Payouts</h3>
                <p className="benefit-text layer-2">
                  Get your Earned Commission Monthly
                </p>
              </div>
              <div className="benefit-item layer-2">
                <div className="icon layer-2 icon-layer-2">
                  <img src={group25} alt="group25" />
                </div>
                <h3 className="benefit-title layer-2">Onboarding Support</h3>
                <p className="benefit-text layer-2">
                  Our team helps you onboard Customers Easily
                </p>
              </div>
              <div className="benefit-item layer-2">
                <div className="icon layer-2 icon-layer-2">
                  <img src={group26} alt="group26" />
                </div>
                <h3 className="benefit-title layer-2">Tracking Dashboard</h3>
                <p className="benefit-text layer-2">
                  Track all your Conversions via our Affiliate Dashboard
                </p>
              </div>
            </div>
          </div>
          <div id="become_div">
            <button className="Home-button">Become a Partner</button>
          </div>
          <div className="home_affilate_conatiner">
            <div className="affiliate-container">
              <h1 className="affiliate-heading">
                Join the Affiliate Program with us
              </h1>
              <div className="info-card-section">
                <div className="info-card infocard-1">
                  <h2 className="info-card-title">Sign Up</h2>
                  <p className="info-card-description">
                    Complete our quick and easy registration form to become an
                    official affiliate partner. Once approved, you’ll gain
                    access to exclusive resources and support.
                  </p>
                </div>
                <div className="info-card">
                  <h2 className="info-card-title">Promote</h2>
                  <p className="info-card-description">
                    Share our HRMS solutions through your network using
                    personalized links and marketing materials. Use your unique
                    affiliate link to track your referrals and earnings.
                  </p>
                </div>
                <div className="info-card">
                  <h2 className="info-card-title">Earn Rewards</h2>
                  <p className="info-card-description">
                    Receive competitive commissions for every successful
                    referral. The more you promote, the more you earn—unlock
                    additional bonuses as you reach new milestones!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="become_div">
            <button className="Home-button">Become a Affiliate</button>
          </div>
        </div>
        <div className="layer5">
          <div className="app-container">
            <div className="client-description">
              <img
                src={clients[currentIndex].img}
                alt="client"
                className="client-img-large"
                id={`client_img_${clients[currentIndex].id}`}              />
              <div className="description-text">
                <h2>{clients[currentIndex].name}</h2>
                <p>{clients[currentIndex].description}</p>
              </div>
              <div className="cover_container"></div>
            </div>
            <img src={polygonImg} alt="polygonImg" className="polygon-img" />
            {/* <div className="polygon-img"></div> */}

            <div className="client-carousel">
              {visibleIndices.map((index, position) => (
                <div
                  key={index}
                  className={`carousel-image ${
                    position === 2 ? "active" : position < 2 ? "left" : "right"
                  }`}
                >
                  <img src={clients[index].img} alt={`client-${index}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="layer6-bg">
          <img src={vector479} alt="vector479" className="vector_image" />
          <div className="layer6">
            <div
              className={`layer6_content1 ${isHovered1 ? "hovered" : ""}`}
              onMouseEnter={() => setIsHovered1(true)}
            >
              <h1>5 Key Benefits of Implementing HRMS Software</h1>
              <p>
                Uncover the advantages of adopting HRMS software in your
                organization. From streamlining payroll processes to improving
                recruitment efficiency, learn how HRMS can transform your HR
                department.
              </p>
              <img
                src={logo}
                style={{ marginLeft: "6vw", marginTop: "5vw" }}
                alt="logo"
                className="hidden_image2"
              />
            </div>

            <div
              className={`layer6_content1 ${isHovered2 ? "hovered" : ""}`}
              onMouseEnter={() => setIsHovered2(true)}
            >
              <h1>Top Features to Look for in HRMS Software</h1>
              <p>
                Read a real-world success story of a client who streamlined
                their HR processes using MakeCRMs HRMS software. Learn about the
                challenges they faced and the solutions we provided.
              </p>

              <img
                src={logo}
                style={{ marginLeft: "6vw", marginTop: "5vw" }}
                alt="logo"
                className="hidden_image2"
              />
            </div>
          </div>
        </div>
        <div className="home_icon_movement_div">
          <IconsMovement />
        </div>
        <PreFooter />
        <Footer />
        <Scrolling />
      </div>
    </>
  );
};

export default Home;

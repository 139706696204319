import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
// import BlogNav from "../../Assets/NavBar/BlogNav.jpg";
import "./Blogs.css";
import blogimg1 from "../../assets/blog_image_1.png";
import blogimg2 from "../../assets/blog-img-2.png";
import blogimg3 from "../../assets/blog-img-3.png";
import Ellipse1 from "../../assets/blog-page-round-img.png";
import Ellipse2 from "../../assets/blog-page-round-img.png";
import Footer from "../../components/Footer/Footer";
import PreFooter from "../../components/PreFooter/PreFooter";
import IconsMovement from "./../../components/IconsMovement/IconsMovement";
// import {
//   database,
//   storage,
//   ref,
//   set,
//   push,
//   storageRef,
//   uploadBytes,
//   getDownloadURL,
//   get,
// } from "../../components/Firebase/Firebase";
import { storage, database } from "../../components/Firebase/Firebase";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { ref, push, set, get } from "firebase/database";
// import { dataShare } from "../../App";
import Scrolling from "../../components/ScrollToTop/Scroll";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
// import './styles.css';
import { Autoplay } from "swiper/modules";

export default function Blog() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogBool, setblogBool] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    authorName: "",
    image: null,
  });
  const [isMobile, setIsMobile] = useState(false);
  const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(true);
  console.log(isAutoplayEnabled, "isAutoplayEnabled");
  

  // const [count, setCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check the initial screen size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const {setScrool} = useContext(dataShare);
  // useEffect(()=>{
  //   setScrool({X:0,Y:0})
  // },[]);

  const Blog = blogBool ? blogs.slice(0, 3) : blogs.slice(0, blogs.length);

  // console.log(Blog,"Blog");

  const handleBool = () => {
    // console.log("change BOOL")
    setblogBool(!blogBool);
  };


  const RecentBlogsInfo = [
    {
      imageUrl: blogimg2,
      title: "Why large industries are moving to react",
      content:
        "Large companies create big enterprise applications for a large and critical userbase and they are very expensive to build.",
      authorName: "Robert Bowney Jr",
      date: "24th May 2024",
    },
    {
      imageUrl: blogimg3,
      title: "Is It the Best time to become a JavaScript Developer?",
      content:
        "During earlier days JavaScript — and web development in general — when everything felt brittle.",
      authorName: "Robert Bowney Jr",
      date: "24th May 2024",
    },
    // {
    //   imageUrl: RcB3,
    //   title: "Career move — how to become a UI Engineer",
    //   content:
    //     "Starting or switching careers in UI Engineering requires navigating its vast ecosystem.",
    //     authorName: "Robert Bowney Jr",
    //   date: "24th May 2024",
    // },
  ];

  const handleApply = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload image to Firebase Storage
    const imageRef = storageRef(storage, `blog_images/${formData.image.name}`);
    await uploadBytes(imageRef, formData.image);
    const imageUrl = await getDownloadURL(imageRef);

    // Store blog data in Firebase Realtime Database
    const blogRef = push(ref(database, "blogs/hrms"));
    await set(blogRef, {
      title: formData.title,
      content: formData.content,
      authorName: formData.authorName,
      imageUrl,
      date: new Date().toLocaleDateString(),
    });

    alert("Blog submitted successfully!");
    fetchBlogs();
    setIsModalOpen(false);
  };
  const fetchBlogs = async () => {
    const blogRef = ref(database, "blogs/hrms");
    const snapshot = await get(blogRef);
    if (snapshot.exists()) {
      setBlogs(Object.values(snapshot.val()));
      // setBlogs([...RecentBlogsInfo, Object.values(snapshot.val())])
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  const handleSlideClick = (swiper) => {

    if (isAutoplayEnabled) {
      swiper.autoplay.stop(); 
      
    } else {
      swiper.autoplay.start(); 
      
    }
    setIsAutoplayEnabled(!isAutoplayEnabled); 
  };

  return (
    <div className="blogs" >
      <Navbar title={"Know it all from the blogs"} />
      <div className="Main_Blog_Bg">
        <div className="blog_main_heading_shadow">
          <h1 className="blog-main-heading">BLOG</h1>
        </div>
        <div className="Blog_Main">
          <div>
            <h3>Top blogs</h3>
            <div className="Top_Blogs_Container">
              <div className="Blog_Container">
                <div className="sub_Blog_Container1">
                  <img src={blogimg1} alt="Blog_Img"></img>
                  <div className="Top_Blog_Content">
                    <h1>Top Features to Look for in HRMS Software</h1>
                    <p>
                      Read a real-world success story of a client who
                      streamlined their HR processes using MakeCRMs HRMS
                      software. Learn about the challenges they faced and the
                      solutions we provided.
                    </p>
                    <div className="Blog_person_Info">
                      <div className="person1">
                        <img src={Ellipse1} alt="Elipse"></img>
                        <p id="dot_img">Robert Bowney Jr</p>
                      </div>
                      <div className="person2">
                        <img src={Ellipse2} alt="Elipse"></img>
                        <p>24th May 2024</p>
                      </div>
                    </div>
                    <button>Read more</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="BlogsMainDiv">
            <h3>Recent Blogs</h3>
            <div className="blogs_cards_container">
              {/* <div className="Recent_Blogs_Container" >
                {Blog.map((Card, index) => (
                  <div className="Single_Blog_Container" key={index}  style={{
                    ...(isMobile && index === Blog.length - 1 ? { marginRight: "7vw" } : {}),
                  }}>
                    <div>
                      <img
                        src={Card.imageUrl}
                        alt="AI_Img"
                        className="BlogImage"
                      ></img>
                    </div>
                    <div className="Blog_title_div">
                      <p className="BlogTitle">{Card.title}</p>
                    </div>
                    <div className="Blog_content_div">
                      <p className="BlogContent">{Card.content}</p>
                    </div>
                    <div className="Blog_Persons_Info">
                      <div className="persons1">
                        <img src={Ellipse1} id="blog_img_1" alt="Elipse"></img>
                        <p id={`dot_img_${index}`}>{Card.authorName}</p>
                      </div>
                      <div className="persons2">
                        <img src={Ellipse2} id="blog_img_2" alt="Elipse"></img>
                        <p>{Card.date}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="Recent_Blogs_Container">
                {isMobile ? ( 
                  <Swiper
                    modules={[Autoplay]}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    onClick={(swiper) => handleSlideClick(swiper)}
                    // className="mySwiper"
                  >
                    {Blog.map((Card, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="Single_Blog_Container"
                          style={{
                            ...(isMobile && index === Blog.length - 1
                              ? { marginRight: "7vw" }
                              : {}),
                          }}
                        >
                          <div>
                            <img
                              src={Card.imageUrl}
                              alt="AI_Img"
                              className="BlogImage"
                            />
                          </div>
                          <div className="Blog_title_div">
                            <p className="BlogTitle">{Card.title}</p>
                          </div>
                          <div className="Blog_content_div">
                            <p className="BlogContent">{Card.content}</p>
                          </div>
                          <div className="Blog_Persons_Info">
                            <div className="persons1">
                              <img
                                src={Ellipse1}
                                id="blog_img_1"
                                alt="Elipse"
                              />
                              <p id={`dot_img_${index}`}>{Card.authorName}</p>
                            </div>
                            <div className="persons2">
                              <img
                                src={Ellipse2}
                                id="blog_img_2"
                                alt="Elipse"
                              />
                              <p>{Card.date}</p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  // For non-mobile screens, display the blogs without autoplay
                  <div className="Recent_Blogs_Container">
                    {Blog.map((Card, index) => (
                      <div className="Single_Blog_Container" key={index}>
                        <div>
                          <img
                            src={Card.imageUrl}
                            alt="AI_Img"
                            className="BlogImage"
                          />
                        </div>
                        <div className="Blog_title_div">
                          <p className="BlogTitle">{Card.title}</p>
                        </div>
                        <div className="Blog_content_div">
                          <p className="BlogContent">{Card.content}</p>
                        </div>
                        <div className="Blog_Persons_Info">
                          <div className="persons1">
                            <img src={Ellipse1} id="blog_img_1" alt="Elipse" />
                            <p id={`dot_img_${index}`}>{Card.authorName}</p>
                          </div>
                          <div className="persons2">
                            <img src={Ellipse2} id="blog_img_2" alt="Elipse" />
                            <p>{Card.date}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={handleBool} className="ViewMoreBlogsBTN">
                {blogBool ? "View More" : "View Less"}
              </button>
            </div>
            <div className="Main_Button">
              <button className="Main_Btn" onClick={handleApply}>
                Contribute now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Blog_Footer">
        <IconsMovement />
        <PreFooter />
        <Footer />
        <Scrolling />
      </div>

      {isModalOpen && (
        <div className="application-modal">
          <div className="application-modal-content">
            <button
              className="close-button"
              onClick={handleCloseModal}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                fontSize: "3vw",
                cursor: "pointer",
                position: "absolute",
                top: "0.5vw",
                right: "2vw",
              }}
              important
            >
              &times;
            </button>
            <h2>Contribute</h2>
            <form onSubmit={handleSubmit}>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter your title"
                required
              />
              <label>Content:</label>
              <textarea
                style={{ resize: "none" }}
                name="content"
                value={formData.content}
                onChange={handleChange}
                placeholder="Write your content"
                required
              ></textarea>
              <label>Author Name:</label>
              <input
                type="text"
                name="authorName"
                value={formData.authorName}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
              <label>Image:</label>
              <input
                type="file"
                name="image"
                onChange={handleChange}
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import "./CarouselClientele.css";
import IconOne from "../../assets/mainlogo (1) 1.png";
import IconTwo from "../../assets/logo 1.png";
import Icontext from "../../assets/image-text.png";
import goodtobuyIcon from "../../assets/logo-goodtobuy 5.png";
import IconSnake from "../../assets/image 2917.png";
import IconFive from "../../assets/logo...... 1.png";
const carouselData = [
  {
    image: IconOne, // Placeholder for your image path
    title: "Sandeep",
    role: "Managing Director",
    description:
      "The centralized employee database has made it incredibly easy for us to access vital information. Plus, the payroll management feature saves us hours every month. We highly recommend MakeCRMs!",
  },
  {
    image: IconTwo,
    title: "John Doe",
    role: "CEO",
    description:
      "The performance management tools have truly revolutionized our approach to employee feedback. Our staff feels more engaged and motivated, and we’re seeing great improvements in overall productivity!",
  },
  {
    image: Icontext,
    title: "Jane Smith",
    role: "Chief Operating Officer",
    description:
      "The implementation of MakeCRMs was seamless. The support team was beneficial, and we've seen a marked improvement in our employee management since adopting the system!",
  },
  {
    image: goodtobuyIcon, // Placeholder for your image path
    title: "Goodtobuy",
    role: "Managing Director",
    description:
      "The analytics features in MakeCRMs have given us invaluable insights into our workforce. We're making data-driven decisions that are positively impacting our company culture!",
  },
  {
    image: IconSnake,
    title: "John Doe",
    role: "CEO",
    description:
      "The implementation of MakeCRMs was seamless. The support team was beneficial, and we've seen a marked improvement in our employee management since adopting the system!",
  },
  {
    image: IconFive,
    title: "Jane Smith",
    role: "Chief Operating Officer",
    description:
      "The centralized employee database has made it incredibly easy for us to access vital information. Plus, the payroll management feature saves us hours every month. We highly recommend MakeCRMs!",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null); // Use useRef to store the interval reference

  const startAutoSlide = () => {
    // Start auto sliding after every 5 seconds
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Adjust the interval time
  };

  useEffect(() => {
    startAutoSlide(); // Start auto-slide on mount

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Clear interval on unmount
      }
    };
  }, []);

  const handleNext = () => {
    // Manually trigger the next slide
    setCurrentIndex(
      currentIndex === carouselData.length - 1 ? 0 : currentIndex + 1
    );
    resetAutoSlide();
  };

  const handlePrev = () => {
    // Manually trigger the previous slide
    setCurrentIndex(
      currentIndex === 0 ? carouselData.length - 1 : currentIndex - 1
    );
    resetAutoSlide();
  };

  const resetAutoSlide = () => {
    // Reset auto slide when user interacts manually
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    startAutoSlide();
  };

  return (
    <div className="carousel-container">
      <div className="carousel-content">
        {/* Display description and text */}
        <div className="text-container">
          {/* <div className="quote">“</div> */}
          <p className="description">
           { `"${carouselData[currentIndex].description}"`}
          </p>
          {/* <div className="quote end-quote">”</div> */}
          <div className="user-info">
            <img
              src={carouselData[currentIndex].image}
              alt={carouselData[currentIndex].title}
              className="logo"
            />
            <div className="info">
              <h3 className="title">{carouselData[currentIndex].title}</h3>
              <p className="role">{carouselData[currentIndex].role}</p>
            </div>
          </div>
        </div>
        {/* Arrow Controls */}
        <div className="carousel-arrows">
          <span className="arrow left" onClick={handlePrev}>
            ←
          </span>
          <span className="arrow right" onClick={handleNext}>
            →
          </span>
        </div>
      </div>
    </div>
  );
};

export default Carousel;

import React, { useRef, useEffect, useState } from "react";
import "./AffiliateProgram.css";
import Navbar from "./../../components/Navbar/Navbar";
import IconsMovement from "./../../components/IconsMovement/IconsMovement";
import PreFooter from "./../../components/PreFooter/PreFooter";
import affilitedCardImg from "../../assets/affilited-card-img.png";
import Footer from "../../components/Footer/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Scrolling from "../../components/ScrollToTop/Scroll";
import { IoCloseSharp } from "react-icons/io5";
import Affiliate_image_1 from "../../assets/Affiliate_image_1.png";
import Affiliate_image_2 from "../../assets/Affiliate_image_2.png";
import Affiliate_image_3 from "../../assets/afilicate_image_3.png";
import Affiliate_image_4 from "../../assets/affilicate_image_4.png";
import Affiliate_image_5 from "../../assets/affilicate_image_5.png";
gsap.registerPlugin(ScrollTrigger);
const AffiliateProgram = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // added state variable
  const [isModalOpen2, setIsModalOpen2] = useState(false); // added state

  const [count, setCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let val = 3;
    let scroll = setInterval(() => {
      setCount((prev) => (prev < val - 1 ? prev + 1 : 0));
    }, 10000);

    return () => {
      clearInterval(scroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check the initial screen size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    return () => {
      gsap.killTweensOf(imageRefOne.current);
      gsap.killTweensOf(imageRefTwo.current);
      gsap.killTweensOf(imageRefThree.current);
    };
  }, []);

  const imageRefOne = useRef(null);
  const imageRefTwo = useRef(null);
  const imageRefThree = useRef(null);
  const buttonRefAft = useRef(null);
  const outlineRefAft = useRef(null);
  const handleMouseEnterOne = () => {
    gsap.to(imageRefOne.current, {
      scale: 1.2,
      y: -25,
      duration: 1,
      ease: "power2.out",
    }); // Increase size with smooth animation
  };
  const handleMouseLeaveOne = () => {
    gsap.to(imageRefOne.current, {
      scale: 1,
      y: 0,
      duration: 1,
      ease: "power2.out",
    }); // Return to original size with smooth animation
  };
  const handleMouseEnterTwo = () => {
    gsap.to(imageRefTwo.current, {
      scale: 1.2,
      y: -25,
      duration: 1,
      ease: "power2.out",
    }); // Increase size with smooth animation
  };
  const handleMouseLeaveTwo = () => {
    gsap.to(imageRefTwo.current, {
      scale: 1,
      y: 0,
      duration: 1,
      ease: "power2.out",
    }); // Return to original size with smooth animation
  };
  const handleMouseEnterThree = () => {
    gsap.to(imageRefThree.current, {
      scale: 1.2,
      y: -25,
      duration: 1,
      ease: "power2.out",
    }); // Increase size with smooth animation
  };
  const handleMouseLeaveThree = () => {
    gsap.to(imageRefThree.current, {
      scale: 1,
      y: 0,
      duration: 1,
      ease: "power2.out",
    }); // Return to original size with smooth animation
  };
  const moveToButton = () => {
    const button = buttonRefAft.current;
    const outline = outlineRefAft.current;

    gsap.to(outline, {
      x:
        button.offsetLeft -
        outline.offsetLeft +
        (button.offsetWidth - outline.offsetWidth) / 2,
      y:
        button.offsetTop -
        outline.offsetTop +
        (button.offsetHeight - outline.offsetHeight) / 2,
      duration: 0.5,
      ease: "power2.out",
    });
  };

  const resetPosition = () => {
    gsap.to(outlineRefAft.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: "power2.out",
    });
  };

  const handleApply = () => {
    console.log("handleApply called");
    setIsModalOpen(true); // added function to open modal
  };
  const handleCloseModal = () => {
    setIsModalOpen(false); // added function to close modal
  };

  const affiliateData = [
    {
      image: Affiliate_image_3,
      title: "Maximize Your Income",
      description:
        "By joining the MakeCRMs Affiliate Program, you can earn competitive commissions for every successful referral. This creates an additional revenue stream, allowing you to boost your earnings without the need for significant upfront investment.",
      handleMouseEnter: handleMouseEnterOne,
      handleMouseLeave: handleMouseLeaveOne,
      imageRef: imageRefOne,
      bgClass: "affiliate-card-1img-bg",
    },
    {
      image: Affiliate_image_4,
      title: "Access to High-Quality Products",
      description:
        "Promote our top-tier HRMS solutions that are trusted by organizations worldwide. As an affiliate, you’re offering valuable tools that genuinely help businesses streamline their HR processes, making your promotions more effective and rewarding.",
      handleMouseEnter: handleMouseEnterTwo,
      handleMouseLeave: handleMouseLeaveTwo,
      imageRef: imageRefTwo,
      bgClass: "affiliate-card-1img-bg",
    },
    {
      image: Affiliate_image_5,
      title: "Receive Comprehensive Support",
      description:
        "Benefit from dedicated support and extensive training materials designed to help you succeed. We provide you with everything you need to effectively market our solutions, ensuring you feel confident in your promotions.",
      handleMouseEnter: handleMouseEnterThree,
      handleMouseLeave: handleMouseLeaveThree,
      imageRef: imageRefThree,
      bgClass: "affiliate-card-1img-bg",
    },
  ];

  return (
    <div className="affiliate">
      <Navbar />
      <div>
        <div className="affiliate_main_heading_shadow">
          <h1 className="affiliate-main-heading">Affiliate Program</h1>
        </div>
        <div className="affiliate_side">
          <p>Affiliate Program</p>
        </div>
        <div className="bg-content-Affiliate-Program">
          <div className="affiliate-section-1">
            <div className="affiliate-content">
              <p className="small-text">
                Empowering Success Through Collaboration
              </p>
              <h1 className="main-heading">Together, We Thrive</h1>
              <p className="ap_description">
                Join the MakeCRMs Partner Program and unlock new opportunities
                for growth and success! As a partner, you’ll gain access to our
                cutting-edge HRMS solutions, comprehensive training, and
                dedicated support.{" "}
              </p>
              <button
                className="affiliate-button"
                ref={buttonRefAft}
                onMouseEnter={moveToButton}
                onMouseLeave={resetPosition}
                onClick={handleApply}
              >
                Become a Affiliate
              </button>
              <div
                onMouseEnter={moveToButton}
                onMouseLeave={resetPosition}
                className="btn-border-affiliate"
                ref={outlineRefAft}
                onClick={handleApply}
              ></div>
            </div>
            <div className="image-placeholder">
              <img src={Affiliate_image_1} />
            </div>
          </div>
          <div className="grid-section">
            <div className="text-content">
              <h1 className="heading-large">Who Can Be Part of It? </h1>
              <div className="business_grid_div">
                <div className="business-grid1">
                  <p className="grid-button1">Digital marketing agencies</p>
                  <p className="grid-button2">Tech consultants</p>
                  <p className="grid-button3">E-commerce web developers</p>
                </div>
                <div className="business-grid1">
                  <p className="grid-button4">Influencers</p>
                  <p className="grid-button5">Business coaches</p>
                  <p className="grid-button6">Content creators</p>
                </div>
              </div>
            </div>
            <div className="visual-box">
              <img src={Affiliate_image_2} />
            </div>
          </div>
          {/* layer3 */}
          <div className="affiliate-container">
            <h1 className="affiliate-heading">
              Join the Affiliate Program with us
            </h1>
            <div className="info-card-section">
              <div className="info-card infocard-1">
                <h2 className="info-card-title">Sign Up</h2>
                <p className="info-card-description">
                  Complete our quick and easy registration form to become an
                  official affiliate partner. Once approved, you’ll gain access
                  to exclusive resources and support.
                </p>
              </div>
              <div className="info-card">
                <h2 className="info-card-title">Promote</h2>
                <p className="info-card-description">
                  Share our HRMS solutions through your network using
                  personalized links and marketing materials. Use your unique
                  affiliate link to track your referrals and earnings.
                </p>
              </div>
              <div className="info-card">
                <h2 className="info-card-title">Earn Rewards</h2>
                <p className="info-card-description">
                  Receive competitive commissions for every successful referral.
                  The more you promote, the more you earn—unlock additional
                  bonuses as you reach new milestones!
                </p>
              </div>
            </div>
          </div>
          {/* layer-4 */}

          <div className="affiliate-section-layer-4">
            <h2 className="affiliate-title-layer-4">
              Why become an affiliate?
            </h2>
            <p className="affiliate-description-layer-4">
              Joining the MakeCRMs Affiliate Program allows you to earn
              competitive commissions while offering your clients top-tier HRMS
              solutions. Benefit from exclusive marketing resources, training,
              and dedicated support to help you succeed. By partnering with us,
              you enhance your income and provide valuable tools that streamline
              HR processes for organizations. Empower your clients and boost
              your earnings with MakeCRMs!
            </p>
            <div className="affiliate-cards-layer-4">
              {affiliateData.map((card, index) => (
                <div
                  key={index}
                  className="card-layer-4"
                  style={{
                    transform: isMobile
                      ? `translateX(-${count * 118}%)`
                      : "none",
                  }}
                >
                  <img
                    onMouseEnter={card.handleMouseEnter}
                    onMouseLeave={card.handleMouseLeave}
                    ref={card.imageRef}
                    src={card.image}
                    alt={card.title}
                    className={`affiliate-image-layer-3`}
                  />
                  <div className={card.bgClass}></div>
                  <div className="card-content-affiliate">
                    <h3 className="card-title-layer-4">{card.title}</h3>
                    <p
                      className="card-description-layer-4"
                      id={`card_pera_${index}`}
                    >
                      {card.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="affiliate-cards-layer-4">
              <div
                className="card-layer-4"
                style={{
                  transform: isMobile ? `translateX(-${count * 100}%)` : "none",
                }}
              >
                <img
                  onMouseEnter={handleMouseEnterOne}
                  onMouseLeave={handleMouseLeaveOne}
                  ref={imageRefOne}
                  src={Affiliate_image_3}
                  alt="Be more trusted"
                  className="affiliate-image-layer-3"
                />
                <div className="affiliate-card-1img-bg"></div>
                <div className="card-content-affiliate">
                  <h3 className="card-title-layer-4">Maximize Your Income</h3>
                  <p className="card-description-layer-4" id="card_pera">
                    By joining the MakeCRMs Affiliate Program, you can earn
                    competitive commissions for every successful referral. This
                    creates an additional revenue stream, allowing you to boost
                    your earnings without the need for significant upfront
                    investment.
                  </p>
                </div>
              </div>
              <div
                className="card-layer-4"
                style={{
                  transform: isMobile ? `translateX(-${count * 100}%)` : "none",
                }}
              >
                <img
                  onMouseEnter={handleMouseEnterTwo}
                  onMouseLeave={handleMouseLeaveTwo}
                  ref={imageRefTwo}
                  src={Affiliate_image_4}
                  alt="Be more trusted"
                  className="affiliate-image-layer-4"
                />
                <div className="affiliate-card-1img-bg"></div>
                <div
                  className="card-content-affiliate"
                  id="card-content-affiliate_2"
                >
                  <h3 className="card-title-layer-4">
                    Access to High-Quality Products
                  </h3>
                  <p className="card-description-layer-4">
                    Promote our top-tier HRMS solutions that are trusted by
                    organizations worldwide. As an affiliate, you’re offering
                    valuable tools that genuinely help businesses streamline
                    their HR processes, making your promotions more effective
                    and rewarding.
                  </p>
                </div>
              </div>
              <div
                className="card-layer-4"
                style={{
                  transform: isMobile ? `translateX(-${count * 100}%)` : "none",
                }}
              >
                <img
                  onMouseEnter={handleMouseEnterThree}
                  onMouseLeave={handleMouseLeaveThree}
                  ref={imageRefThree}
                  src={Affiliate_image_5}
                  alt="Be more trusted"
                  className="affiliate-image-layer-4"
                  id="image_4"
                />
                <div className="affiliate-card-1img-bg"></div>
                <div className="card-content-affiliate" id="card_4">
                  <h3 className="card-title-layer-4">
                    Receive Comprehensive Support
                  </h3>
                  <p className="card-description-layer-4">
                    Benefit from dedicated support and extensive training
                    materials designed to help you succeed. We provide you with
                    everything you need to effectively market our solutions,
                    ensuring you feel confident in your promotions.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="Affliate_program_iconsMovement">
          <IconsMovement />
        </div>
        {isModalOpen && (
          <div className="application-modal_ap">
            <div className="application-modal-content_ap">
              {/* <button
              className="close-button_ap"
              onClick={handleCloseModal}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                fontSize: "3vw",
                cursor: "pointer",
                position: "absolute",
                top: "0.5vw",
                right: "2vw",
              }}
              important
            >
              &times;
            </button> */}
              <IoCloseSharp
                className="close-button_pp"
                onClick={handleCloseModal}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: "3vw",
                  cursor: "pointer",
                  position: "absolute",
                  top: "0.5vw",
                  right: "2vw",
                  color: "white",
                }}
              />
              <h2>Become An Affiliate</h2>
              <form>
                <label>Full Name:</label>
                <input
                  type="text"
                  placeholder="Enter your full name"
                  required
                />
                <label>Email:</label>
                <input type="email" placeholder="Enter your email" required />
                <label>Phone Number:</label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  required
                />
                <p>
                  Welcome to the Make CRMs Affiliate Program – Let’s Grow and
                  Succeed Together!
                </p>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}
        <PreFooter />
        <Footer />
        <Scrolling />
      </div>
    </div>
  );
};

export default AffiliateProgram;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home.js";
import Features from "./Pages/Features/Features.js";
import AboutUs from "./Pages/AboutUs/AboutUs.js";
import Blog from "./Pages/Blog/Blog.js";
import AffiliateProgram from "./Pages/AffiliateProgram/AffiliateProgram.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.js";
import TermsConditions from "./Pages/TermsConditions/TermsConditions.js";
import ContactUS from "./Pages/ContactUS/ContactUS.js";
import Clientele from "./Pages/Clientele/Clientele.js";
import PartnerProgram from "./Pages/PartnerProgram/PartnerProgram.js";
import Careers from "./Pages/Careers/Careers.js";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Features" element={<Features />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />
          <Route exact path="/Blog" element={<Blog />} />
          <Route
            exact
            path="/AffiliateProgram"
            element={<AffiliateProgram />}
          />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/TermsConditions" element={<TermsConditions />} />
          <Route exact path="/ContactUS" element={<ContactUS />} />
          <Route exact path="/Clientele" element={<Clientele />} />
          <Route exact path="/PartnerProgram" element={<PartnerProgram />} />
          <Route path="/careers" element={<Careers/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React from "react";
import "./TermsConditions.css";
import Navbar from "./../../components/Navbar/Navbar";
import Footer from "./../../components/Footer/Footer";
import PreFooter from "./../../components/PreFooter/PreFooter";
import Scrolling from "../../components/ScrollToTop/Scroll";

const TermsConditions = () => {
  return (
    <div className="terms_conditions_main_div">
      <Navbar />
      <div className="terms_conditions_main">
        {/* <div className="terms_and_conditions_main_heading_shadow"> */}
          <h1 className="terms_conditions_main_h">Terms and Conditions</h1>
        {/* </div> */}
        <div className="terms_conditions_bg_div">
          <div className="terms_and_condition-content-div">
            <p>
              Your acceptance of compliance to these Terms will determine your
              ability to access and use the Service. All users, visitors, and
              others who access or use the website are subject to these terms.
            </p>
            <p>
              You acknowledge that you are responsible for adhering to any
              applicable local laws and that you will be bound by these terms of
              service and all applicable laws and regulations by using this
              website. It is forbidden for you to use or access this site if you
              disagree with any of these terms. The content on this website is
              shielded by relevant trademark and copyright laws.
            </p>
            <p>
              Except as otherwise noted, the content on this website is given to
              you without charge, with no promises or assurances, and is only
              meant to be used for general informational purposes. Make CRMs has
              taken every precaution to guarantee that the data on this website
              is accurate as of the publication date.
            </p>
            <p>
            Make CRMs is been the one of all copyrights, trademarks, and other
              intellectual property rights used on this website. Apart from the
              restricted ability to use the website in compliance with these
              terms, you have no additional rights to it. Information and
              content on this website may be printed off or downloaded for
              personal use. Any portion of this website or its contents may not
              be sold or distributed in any way. Without obtaining prior consent
              from Make CRMs, you are not permitted to use any portion of this
              website on another website.
            </p>
            <p>
              With the exception of what is specifically stated on this website,
              Make CRMs is not liable for the messages you send or receive, the
              content of any webpages, or how you use them.
            </p>
            <h1>Links to external web pages</h1>
            <p>
              The content, privacy practices, and policies of any third-party
              websites or services are outside Make CRMs's control and
              responsibility. Additionally, you understand and agree that
              Make CRMs will not be held directly or indirectly accountable for
              any loss or damage resulting from using or depending on any of the
              products, services, or content made available on or through any of
              these websites or services, or from claims made in connection with
              such use or reliance.
            </p>
            <p>
              It is highly recommended that you carefully review the terms and
              conditions as well as privacy policies of any third-party websites
              or services you use.
            </p>
            <h1>Closure</h1>
            <p>
              Without warning or responsibility, we reserve the right to
              instantly discontinue or suspend your use of our service for any
              reason, including but not limited to when you violate the terms.
            </p>
            <p>
              Any clause in the Terms that should, by definition, last until the
              end of time will do so. Examples of such clauses are ownership
              provisions, warranty disclaimers, indemnity, and liability
              limitations.
            </p>
            <h1>Modifications</h1>
            <p>
              We reserve the right to amend or update these Terms at any moment,
              in our sole discretion. We will make an effort to give at least 30
              days' notice before any new terms take effect if a revision is
              significant. Our sole discretion will be used to determine what
              changes are considered material.
            </p>
            <p>
              You accept to be bound by the updated terms if you use our website
              or log in after those changes take effect. Please discontinue
              using the Service if you do not agree to the updated terms.
            </p>
            <h1>Contact Us</h1>
            <p>
              If you would like to update, remove, or modify any Personal
              Information that we may have about you, or if you have any
              concerns regarding how we have handled any privacy-related issue,
              please write us an email or letter at: (Have to mention our email
              here)
            </p>
            <p>
            2nd Floor
            
              Khanamet, Hitech City Road
              <br />
              Hitech City,
           
              Hyderabad, 500084
          
              India
            </p>
            <p>
              Thanks for taking the time to learn about Make CRMs Terms and
              conditions, and thanks for trusting us to manage your brand.
            </p>
          </div>
        </div>
      </div>
      <PreFooter />
      <Footer />
      <Scrolling/>
    </div>
  );
};

export default TermsConditions;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./Careers.css";
import Navbar from "../../components/Navbar/Navbar";
import PreFooter from "../../components/PreFooter/PreFooter";
import Footer from "../../components/Footer/Footer";
import image1 from "../../assets/careers_img.png";
import career_arrow from "../../assets/careers_arrow.png";
import Scrolling from "../../components/ScrollToTop/Scroll";

const Careers = () => {
  const careerSectionRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false); // added state variable
  const [isModalOpen2, setIsModalOpen2] = useState(false); // added state

  const handleApply = () => {
    console.log("handleApply called");
    setIsModalOpen(true); // added function to open modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // added function to close modal
  };

  const handleApply2 = () => {
    console.log("handleApply2 called");
    setIsModalOpen2(true);
  };
  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
  };
  const handleEmail = () => {
    window.location.href = "mailto:mamatha@makecrms.com";
  };
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  return (
    <div className="careers_main_div">
      <Navbar />
      <div className="careers_main_h">
        <h1>Careers</h1>
        <div className="careers_bg_div">
          <div className="careers_main">
            <h1>Why you should join us?</h1>
            <div className="career-section1">
              <div className="career-section1-content1">
                <div className="career-contents">
                  <h3>A culture of care and commitment</h3>
                  <p>
                    We're a family, building relationships, open communication,
                    commitment, dedication, serving others, lifting each other
                  </p>
                </div>
                <div className="career-contents">
                  <h3>Ever-expanding opportunity</h3>
                  <p>
                    The sky’s the limit to what’s possible and where you’ll take
                    your career.
                  </p>
                </div>
                <div className="career-contents">
                  <h3>Lending a hand</h3>
                  <p>
                    The communities where we live and work are important to us.
                    We believe in giving back and helping those who may need a
                    hand.
                  </p>
                </div>
              </div>

              <div className="career-section1-content1">
                <div className="career-contents">
                  <h3>Balanced benefits</h3>
                  <p>
                    With a comprehensive benefits package that provides
                    flexibility and promotes work/life balance
                  </p>
                </div>
                <div className="career-contents">
                  <h3>Championing Success Together</h3>
                  <p>
                    At HRMS we believe in the power of collaboration and
                    teamwork.
                  </p>
                </div>
                <div className="career-contents">
                  <h3>Be Guided by Experts</h3>
                  <p>
                    Our seasoned professionals will guide and mentor you,
                    providing valuable insights 
                  </p>
                </div>
              </div>
              <img src={image1} alt="carrer-imge" />
            </div>
            <div className="career-section2">
              <h1>Open positions</h1>
              <div className="career-section2-main">
                <div
                  className="career-section2-main-container"
                  ref={careerSectionRef}
                >
                  <div className="career-section2-content">
                    <div className="career-section2-content-box">
                      <h4>AI Architect</h4>
                      <p>New York, NY</p>
                    </div>
                    <button className="apply-button" onClick={handleApply2}>
                      Apply now
                    </button>
                  </div>

                  <div className="career-section2-content">
                    <div className="career-section2-content-box">
                      <h4>Data Engineer</h4>
                      <p>New York, NY</p>
                    </div>
                    <button className="apply-button" onClick={handleApply2}>
                      Apply now
                    </button>
                  </div>
                </div>
                <div className="career-section2-side">
                  <div>
                    <h3>Nothing for you here? Self- application</h3>
                    <img src={career_arrow} alt="arrow" onClick={handleApply} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen2 && (
        <div className="application-modal1">
          <div className="application-modal-content1">
            <div className="vsdl-heading-cancel">
              <button
                className="close-button1"
                onClick={handleCloseModal2}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: "3vw",
                  cursor: "pointer",
                  position: "absolute",
                  top: "-2vw",
                  left: "28vw",
                }}
                important
              >
                &times;
              </button>
              <h1>HRMS HEADING</h1>
            </div>
            <div className="application-modal1-main">
              <div className="application-modal1-heading">
                <h1>HRMS </h1>
              </div>
              <div className="application-modal1-date">
                <p>Posting Date: 21/08/2024</p>
              </div>
            </div>
            <h2>Position</h2>
            <hr />
            <ul>
              <li>lorem epsum</li>
              <li>lorem epsum</li>
              <li>lorem epsum</li>
            </ul>
            <h2>Number of Openings</h2>
            <hr />
            <p>1</p>
            <h2>Location</h2>
            <hr />
            <p>
              Lorem epsum
              <br />
              Lorem epsum
              <br />
              Lorem epsum
              <br />
            </p>
            <h2>Job Duties</h2>
            <hr />
            <p>
              loremThe purpose of lorem ipsum is to create a natural looking
              block of text (sentence, paragraph, page, etc.) that doesn't
              distract from the layout. A practice not without controversy,
              laying out pages with meaningless filler text can be very useful
              when the focus is meant to be on design, not content. The passage
              experienced a surge in popularity during the 1960s when Letraset
              used it on their dry-transfer sheets, and again during the 90s as
              desktop publishers bundled the text with their software. Today
              it's seen all around the web; on templates, websites, and stock
              designs. Use our generator to get your own, or read on for the
              authoritative history of lorem ipsum.
            </p>
            <h2>Education</h2>
            <hr />
            <p>
              loremThe purpose of lorem ipsum is to create a natural looking
              block of text (sentence, paragraph, page, etc.) that doesn't
              distract from the layout. A practice not without controversy,
              laying out pages with meaningless filler text can be very useful
              when the focus is meant to be on design, not content.
            </p>
            <h2>Experience</h2>
            <hr />
            <p>
              Experience of THREE (3) YEARS working with Oracle HCM is required.
              Travel and/or relocation is required to unanticipated client sites
              within USA. International travel is not required. The frequency of
              travel is currently not known as it depends on the client and
              project requirement that cannot be currently anticipated. Employer
              provides Information technology services to various clients in USA
              and hence implementing projects will require such travel.
            </p>
            <button onClick={handleEmail} >
              To apply your resume mamatha@makecrms.com
            </button>
            <p>
              Experience of THREE (3) YEARS working with Oracle HCM is required.
              Travel and/or relocation is required to unanticipated client sites
              within USA. International travel is not required. The frequency of
              travel is currently not known as it depends on the client and
              project requirement that cannot be currently anticipated. Employer
              provides Information technology services to various clients in USA
              and hence implementing projects will require such travel.
            </p>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="application-modal">
          <div className="application-modal-content">
            <button
              className="close-button"
              onClick={handleCloseModal}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                fontSize: "3vw",
                cursor: "pointer",
                position: "absolute",
                top: "0.5vw",
                right: "2vw",
              }}
              important
            >
              &times;
            </button>
            <h2>Application Form</h2>
            <form>
              <label>Full Name:</label>
              <input type="text" placeholder="Enter your full name" required />
              <label>Email:</label>
              <input type="email" placeholder="Enter your email" required />
              <label>Phone Number:</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                required
              />
              <label>Resume:</label>
              <input type="file" required />
              <button type="submit">Apply Here</button>
            </form>
          </div>
        </div>
      )}

      <div className="careers_page_footer_section">
        <PreFooter />
        <Footer />
        <Scrolling />
      </div>
    </div>
  );
};

export default Careers;

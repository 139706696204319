import React from "react";
import "./Clientele.css";
import Navbar from "./../../components/Navbar/Navbar";
import PreFooter from "./../../components/PreFooter/PreFooter";
import Footer from "./../../components/Footer/Footer";
import IconOne from "../../assets/mainlogo (1) 1.png";
import IconTwo from "../../assets/logo 1.png";
import Icontext from "../../assets/image-text.png";
import goodtobuyIcon from "../../assets/logo-goodtobuy 5.png";
import IconSnake from "../../assets/image 2917.png";
import IconFive from "../../assets/logo...... 1.png";
import esIcon from "../../assets/es-icon.png";
import clienteletwoimgs from "../../assets/clientele-two-imgs.png";
import arrowfullImg from "../../assets/full-arrow-img.png";
import Slider from "react-slick";
import Img3Clientele from "../../assets/image 10.png";
import leftimg from "../../assets/left-arrow.png";
import rightimg from "../../assets/rigjt-arrow.png";
import loveimg from "../../assets/love-image.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselClientele from "../../components/CarouselClientele/CarouselClientele";
import Scrolling from "../../components/ScrollToTop/Scroll";
import { Link } from "react-router-dom";
const slides = [
  {
    image: IconOne, // Replace with your image URLs
    title: "Slide 1 Title",
    name: "John Doe",
    description: "“The centralized employee database has made it incredibly easy for us to access vital information. Plus, the payroll management feature saves us hours every month. We highly recommend MakeCRMs!”",
  },
  {
    image: IconTwo,
    title: "Slide 2 Title",
    name: "Jane Smith",
    description: "“The performance management tools have truly revolutionized our approach to employee feedback. Our staff feels more engaged and motivated, and we’re seeing great improvements in overall productivity.!”",
  },
  {
    image: Icontext,
    title: "Slide 3 Title",
    name: "Alice Johnson",
    description: "“ The implementation of MakeCRMs was seamless. The support team was beneficial, and we've seen a marked improvement in our employee management since adopting the system.!”",
  },
  {
    image: goodtobuyIcon,
    title: "Slide 4 Title",
    name: "Alice Johnson",
    description: "“ The analytics features in MakeCRMs have given us invaluable insights into our workforce. We're making data-driven decisions that are positively impacting our company culture.!”",
  },
];

const Clientele = () => {
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-next`} onClick={onClick}>
        <span>&#8250;</span>
      </div>
    );
  };

  // Custom previous arrow
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-prev`} onClick={onClick}>
        <span>&#8249;</span>
      </div>
    );
  };
  const settings = {
    dots: true, // Dots at the bottom
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Autoplay feature
    autoplaySpeed: 3000, // Time interval for automatic slide
    nextArrow: <NextArrow />, // Custom Next Arrow
    prevArrow: <PrevArrow />, // Custom Previous Arrow
  };
  // Custom next arrow

  return (
    <>
      <Navbar />
      <div className="clientele-main">
        {/* <div className="clientele_main_h_shadow">
        <h1 className="Clientele-main-h">Clientele</h1>
        </div> */}
        <div className="Clientele-content-div">
          <img src={arrowfullImg} alt="arrowfullImg" className="arrowfullImg" />
          <h1 className="Clientele-second-h">Our Client's Word.</h1>
          <img
            src={Img3Clientele}
            className="third-img-clientele"
            alt="Img3Clientele"
          />
        </div>

        {/* layer-1 */}

        <div className="layer-1-carousel">
          <CarouselClientele />
        </div>
        <div className="layer-3-clientele">
          <div className="main-container-clientele">
            {/* Trusted By Section */}
            <section className="trusted-section-clientele">
              <h3 className="trusted-heading-clientele">Trusted by</h3>
              <div className="logo-grid-clientele">
                {/* Logo grid items */}
                <div className="logo-item-clientele left-no-border">
                  <p className="text-clientele">
                    We saved a special <br /> spot just for you
                    <br />
                    <img src={loveimg} alt="loveimg" className="loveimg" />
                  </p>
                </div>
                <div className="logo-item-clientele">
                  <img src={IconTwo} alt="IconTwo" className="IconTwo" />
                </div>
                <div className="logo-item-clientele">
                  <img src={IconFive} alt="IconFive" className="IconFive" />
                </div>
                <div className="logo-item-clientele right-no-border">
                  <img src={Icontext} alt="Icontext" className="Icontext" />
                </div>
                <div className="logo-item-clientele left-no-border ">
                  <img src={IconSnake} alt="IconSnake" className="IconSnake" />
                </div>
                <div className="logo-item-clientele">
                  <img
                    src={goodtobuyIcon}
                    alt="goodtobuyIcon"
                    className="goodtobuyIcon"
                  />
                </div>
                <div className="logo-item-clientele">
                  <img src={IconOne} alt="IocnOne" className="IocnOne" />
                </div>
                <div className="logo-item-clientele right-no-border">
                  <img src={esIcon} alt="esIcon" className="esIcon-img" />
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="layer-4-clientele">
          <img
            src={clienteletwoimgs}
            alt=""
            className="layer-4-clientele-first-img"
          />
          <h1 className="layer-4-heading">Ready for a Special Demo?</h1>
        </div>
        <div className="last-layer-clientele">
          <hr className="lastContainer-hr-line-left" />
          <Link to="https://calendly.com/mamata-makecrms/hrms-demo?month=2024-11" target="blank">
          <button className="clientele-book-btn">Book a Demo</button>
          </Link>
          <hr className="lastContainer-hr-line-right" />
        </div>
      </div>
      <PreFooter />
      <Footer />
      <Scrolling />
    </>
  );
};

export default Clientele;

import React, { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { Link } from "react-router-dom";
import "./PreFooter.css";
const PreFooter = () => {
  const buttonRef = useRef(null);
  const outlineRef = useRef(null);
  const moveToButton = () => {
    const button = buttonRef.current;
    const outline = outlineRef.current;

    gsap.to(outline, {
      x:
        button.offsetLeft -
        outline.offsetLeft +
        (button.offsetWidth - outline.offsetWidth) / 2,
      y:
        button.offsetTop -
        outline.offsetTop +
        (button.offsetHeight - outline.offsetHeight) / 2,
      duration: 0.5,
      ease: "power2.out",
    });
  };



  const resetPosition = () => {
    gsap.to(outlineRef.current, {
      x: 0,
      y: 0,
      duration: 0.5,
      ease: "power2.out",
    });
  };
  return (
    <div className="last-container-features">
      <h1 className="last-container-heading-features" >
        Reserve A Time For A Demo
      </h1>
      <p className="last-container-para-features para-color" id="para_container">
        Ready to see MakeCRMs in action? Schedule a personalized demo at your
        convenience! Our team will walk you through our HRMS software,
        showcasing its key features and benefits tailored to your organization’s
        needs.
      </p>
      <Link to='https://calendly.com/mamata-makecrms/hrms-demo?month=2024-11' target="blank">
        <div
          ref={outlineRef}
          className="outline"
          onMouseEnter={moveToButton}
          onMouseLeave={resetPosition}
        >
        </div>
      </Link>

      <Link to="https://calendly.com/mamata-makecrms/hrms-demo?month=2024-11" target="blank">
        <button
          className="last-container-button"
          ref={buttonRef}
          onMouseEnter={moveToButton}
          onMouseLeave={resetPosition}
        
        >
          Book A Demo
        </button>
      </Link>
    </div>
  );
};
export default PreFooter;

import React from "react";
import "./PrivacyPolicy.css";
import Navbar from "./../../components/Navbar/Navbar";
import Footer from "./../../components/Footer/Footer";
import PreFooter from "./../../components/PreFooter/PreFooter";
import Scrolling from "../../components/ScrollToTop/Scroll";
const PrivacyPolicy = () => {
  return (
    <div className="privacyPolicy">
      <Navbar />
      <div className="privacy-policy-main">
        <div className="privacy_policy_heading_shadow">
          <h1 className="privacy-policy-main-heading">Privacy Policy</h1>
        </div>
        <div className="privacy-policy-bg-div">
          <div className="privacy-policy-content-div">
            <p>
              We value your visiting our website and using our Brand MakeCMR's
              Services. The what, how, and why of the information we gather from
              you when you use our services or visit our website are all
              explained in this policy. It also describes how we specifically
              utilize and disclose that data. We always protect your privacy by
              not disclosing any personal information we may have obtained from
              your use of our website.
            </p>
            <p>
              You may read about our rules on this page about how we will
              collect, use, and disclose personal information about you when you
              use our services. Your information won't be used or shared with
              anybody else than what this privacy policy specifies.
            </p>
            <p>
              Your Personal Information is used by us to deliver and enhance the
              Service. You consent to the collection and use of information in
              line with this policy by using the Service. Terms used in this
              Privacy Policy have the same meanings as those found in our Terms
              and conditions, unless otherwise indicated.
            </p>
            <p>
              We may request certain personally identifying information from you
              while you use our service so that we can get in touch with or
              identify you. Your name, phone number, company information, and
              job title are just a few examples of personally identifying
              information that may be disclosed.
            </p>
            <h1>To encourage you and others to use our services.</h1>
            <p>
              Furthermore, we might utilise the data we gather to market to you
              about our services or recommend other deals we have for you to
              take advantage of. We might also use your Personal Information to
              market our services to prospective customers and other users who
              might be similar to you.
            </p>
            <h1>
              To provide you with promotional and informative material that you
              are free to accept.
            </h1>
            <p>
              If you would want to no longer receive our promotional emails,
              simply follow the unsubscribe instructions found in each email. If
              you would want to no longer receive our promotional emails, simply
              follow the unsubscribe instructions found in each email.
            </p>
            <h1>Cookies</h1>
            <p>
              Cookies are little data files that could contain a unique,
              anonymous identity. Cookies are files that websites send to your
              browser and store on the hard disk of your computer.
            </p>
            <p>
              "Cookies" are tools we employ to gather data. You may set your
              browser to alert you when a cookie is being sent or to reject
              cookies altogether. On the other hand, you might only be able to
              use some of our websites if you reject cookies.
            </p>
            <h1>Service Providers</h1>
            <p>
              We may utilize outside businesses and people to help us with our
              website, to supply information on our behalf, carry out
              website-related tasks, or to help us with user analytics.
            </p>
            <p>
              These third parties are required to keep your personal information
              private and are only permitted access to it for the purposes of
              carrying out these duties on our behalf.
            </p>
            <h1>Security</h1>
            <p>
              We take reasonable and adequate precautions, keeping in mind the
              risks associated with processing and the nature of the Personal
              Information, to guard against loss, misuse, and unauthorised
              access, disclosure, modification, and destruction. Although we
              care about the security of your personal information, keep in mind
              that no method of electronic data transfer over the Internet or
              technique of electronic data storage is 100% safe. We can't
              guarantee the complete security of your personal information, even
              though we make every effort to safeguard it using commercially
              reasonable methods.
            </p>
            <h1>Modifications To This Privacy Statement</h1>
            <p>
              This privacy statement is subject to change at any time and from
              time to time. The version date at the bottom of this Privacy
              Policy indicates the most recent version of the document. Any
              revisions or modifications take effect right away upon
              notification, which we may provide in a number of ways, such as by
              publishing a new copy of this privacy statement or another notice
              on the website, among other ways.
            </p>
            <p>
              As your ongoing use of the website indicates that you agree to be
              governed by this privacy policy going forward, we encourage you to
              review it frequently to be informed of changes that may impact
              you. The versions of this privacy policy that were in effect on
              each date you visited the website are considered to be genuine,
              complete, legitimate, original, and enforceable copies that we
              have saved electronically or in another appropriate manner.
            </p>
            <h1>Contact Us</h1>
            <p>
              If you would like to update, remove, or modify any Personal
              Information that we may have about you, or if you have any
              concerns regarding how we have handled any privacy-related issue,
              please write us an email or letter at: (Have to mention our email
              here).
            </p>
            <p> Tridizi  Digital Innovations  is the parent company of Make CRMs.</p>
            <p>
              2nd Floor
             
              Khanamet, Hitech City Road
            <br/>
              Hitech City,
             
              Hyderabad, 500084
            
              India
            </p>
            <p>
              We appreciate your interest in learning about Make CRMs privacy
              policy and your confidence in us to look after your brand.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="privacy_policy_prefooter"> */}
      <PreFooter />
      {/* </div> */}
      <Footer />
      <Scrolling />
    </div>
  );
};
export default PrivacyPolicy;

import React, { useEffect, useRef, useState } from "react";
import "./ContactUS.css";
import Navbar from "./../../components/Navbar/Navbar";
import Footer from "./../../components/Footer/Footer";
import CarGIT from "../../assets/movingCar.gif";
import telephoneImg from "../../assets/contact_image_1.png";
import carAnimation from "../../assets/car Animation.gif";
import telephoneAnimation from "../../assets/telehoneAnimation.gif";
import emailAnimation from "../../assets/emailAnimation.gif";
import IconsMovement from "./../../components/IconsMovement/IconsMovement";
import Scrolling from "../../components/ScrollToTop/Scroll";
import { GoArrowUpRight } from "react-icons/go";
import faceBookIcon from "../../assets/facebookIcon.png";
import instIcon from "../../assets/instsIcon.png";
import xIcon from "../../assets/XIcon.png";
import linkedinIcon from "../../assets/LinkedinIcon.png";
import youtubeIcon from "../../assets/YouTubeIcon.png";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactUS = () => {
  const [showButton, setShowButton] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    services: "",
    number: "",
    message: "",
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const formRef = useRef(null);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.scrollToForm) {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  }, [state]);

  const detailsHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  const submitHandler = (e) => {
    e.preventDefault();
    if(details.name && details.email && details.number && details.message && details.services){
    emailjs
      .sendForm("service_z7sabb7", "template_o2gt8m6", e.target, {
        publicKey: "Zi6lxzE2jsI5BKGiV",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );

    setDetails({
      name: "",
      email: "",
      services: "",
      number: "",
      message: "",
    });
  }
  };

  return (
    <div className="contactUs">
      <Navbar />
      <div className="contactUs-main">
        <div className="telephoneImg-container">
          <img src={telephoneImg} alt="telephoneImg" className="telephoneImg" />
          <div className="border_container"></div>
        </div>
        <div className="contactUs-bg-div">
          <div className="layer-2contactUs">
            <h1 className="contactUs-lyr2-h">Let’s get in touch</h1>
            <p className="contactUs-lyr2-para">
              We’re here to help! Whether you have questions about our HRMS
              solutions, need support, or want to schedule a demo, our team is
              ready to assist you.{" "}
            </p>
            <div className="contact-rows-div">
              <div className="each-col-contact">
                <img src={carAnimation} alt="CarGIT" className="CarGIT" />
                <hr className="car-track" />
                <h3>We're at</h3>
                <div className="col-contact-1">
                  <p>
                    2nd Floor Khanamet, Hitech City Road Hitech City, <br />
                    Hyderabad, 500084 India
                  </p>
                </div>
              </div>
              <div className="each-col-contact box-2">
                <img
                  src={telephoneAnimation}
                  alt="CarGIT"
                  className="telehone-amimation"
                />
                <h3 className="time_in_contact_page">
                  Contact Us (10 AM - 7 PM)
                </h3>
                <div className="col-contact-2">
                  <p>+91 9493632575</p>
                </div>
              </div>
              <div className="each-col-contact">
                <img src={emailAnimation} alt="CarGIT" className="email-gif" />
                <h3 className="email_in_contact_page">Email Us</h3>
                <div className="col-contact-3">
                  <p>mamata@makecrms.com</p>
                </div>
              </div>
            </div>
            {/* Scroll button visible only from layer 2 to IconsMovement */}
            {showButton && (
              <GoArrowUpRight className="btn-to" onClick={scrollToTop} />
            )}
            {/* Contact form */}
            <div className="contact_follow_us_div">
              <div className="contact_page_follow_us_btn">
                <div className="follow_btn">
                  <p>Follow Us on</p>
                </div>
                <span className="arrow_right">→</span>
              </div>
              <div className="contact_page_follow_optns" ref={formRef}>
                <img src={faceBookIcon} alt="facebook-icon" />
                <img src={instIcon} alt="instIcon" />
                <img src={xIcon} alt="xIcon" />
                <img src={linkedinIcon} alt="linkedinIcon" />
                <img src={youtubeIcon} alt="youtubeIcon" />
              </div>
            </div>
            <div className="contact-form-container">
              <form className="contact-form" onSubmit={submitHandler}>
                <div className="form-group">
                  <div className="contact_input_field1">
                    <input
                      type="text"
                      placeholder="Name"
                      className="input-field"
                      value={details.name}
                      onChange={detailsHandler}
                      name="name"
                    />
                  </div>
                  <div className="contact_input_field2">
                    <input
                      type="email"
                      placeholder="E mail"
                      className="input-field"
                      value={details.email}
                      onChange={detailsHandler}
                      name="email"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="contact_input_field3">
                    <input
                      type="text"
                      placeholder="Service"
                      className="input-field"
                      value={details.services}
                      onChange={detailsHandler}
                      name="services"
                    />
                  </div>
                  <div className="contact_input_field4">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      className="input-field"
                      value={details.number}
                      onChange={detailsHandler}
                      name="number"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="contact_textarea">
                    <textarea
                      placeholder="Message..."
                      className="textarea-field_contact"
                      value={details.message}
                      onChange={detailsHandler}
                      name="message"
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="submit-btn">
                    Send a Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="contactus_icons_movement">
        <IconsMovement />
      </div>
      <Footer />
      <Scrolling />
    </div>
  );
};

export default ContactUS;

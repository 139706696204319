// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAE7QYfIQPtSyT5AYxd0Ih0Nz_EnyxsNPA",
  authDomain: "hrms-website.firebaseapp.com",
  databaseURL: "https://hrms-website-default-rtdb.firebaseio.com",
  projectId: "hrms-website",
  storageBucket: "hrms-website.appspot.com",
  messagingSenderId: "80513858592",
  appId: "1:80513858592:web:a8bc3e6ce3ddf5611bd045",
  measurementId: "G-HBY8R3ZF7Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app)
const storage = getStorage(app)


export { database, storage, ref, set, push, get, storageRef, uploadBytes, getDownloadURL };
// import React, { useState, useEffect } from "react";
// import "./Navbar.css";
// import logo from "../../assets/Group 1000005512 (1) 3.png";
// import { Link, useLocation } from "react-router-dom";
// import { ImCross } from "react-icons/im";
// import { FaBars } from "react-icons/fa";

// const Navbar = () => {
//   const location = useLocation();
//   const [activeLink, setActiveLink] = useState(location.pathname);
//   const [active, setActive] = useState(false)

//   useEffect(() => {
//     setActiveLink(location.pathname);
//   }, [location]);

//   const handleClickNav =()=>{
//     setActive(!active)
//   }
//   return (
//     <div className="nav-bar-bg">
//       <div className="navbar_container">
//         <div className="logo_image">
//           <img src={logo} alt="logo" />
//           {active ? (
//               <div>
//                 <ImCross className="CrossMark" onClick={handleClickNav} />
//               </div>
//             ) : (
//               <div>
//                 <FaBars className="CuroselIcon" onClick={handleClickNav} />
//               </div>
//             )}
//         </div>
//         <div className="nav_items">
//           <ul className={`nav-unorder-list ${active ? "shownavItems" : "noNav"}`}>
//             <Link to="/">
//               <li className={activeLink === "/" ? "active" : "nav_item home"} onClick={handleClickNav}>
//                 Home
//               </li>
//             </Link>
//             <Link to="/Features">
//               <li
//                 className={activeLink === "/Features" ? "active" : "nav_item"} onClick={handleClickNav}
//               >
//                 Features
//               </li>
//             </Link>
//             <Link to="/AboutUs">
//               <li className={activeLink === "/AboutUs" ? "active" : "nav_item"} onClick={handleClickNav}>
//                 About Us
//               </li>
//             </Link>
//             <Link to="/Clientele">
//               <li
//                 className={activeLink === "/Clientele" ? "active" : "nav_item"}onClick={handleClickNav}
//               >
//                 Clientele
//               </li>
//             </Link>
//             <Link to="/ContactUS">
//               <li
//                 className={activeLink === "/ContactUS" ? "active" : "nav_item"}onClick={handleClickNav}
//               >
//                 Contact Us
//               </li>
//             </Link>
//           </ul>
//         </div>
//         <div className="nav_button">
//           <button>
//             <Link to="/ContactUs">HRMS Demo</Link>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../assets/Group 1000005512 (1) 3.png";
import { Link, useLocation } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const handleClickNav = () => {
    setActive(!active);
  };

  return (
    <div className="nav-bar-bg">
      <div className="navbar_container">
        <div className="mobile_view">
          <div  className="logo_image">
            {" "}
            <img src={logo} alt="logo" />
          </div>
          <div className="mobile_view_icons">
          {active ? (
            <div>
              <ImCross className="CrossMark" onClick={handleClickNav} />
            </div>
          ) : (
            <div>
              <FaBars className="CuroselIcon" onClick={handleClickNav} />
            </div>
          )}
          </div>
        </div>
        <div className={`nav_items ${active ? "show" : ""}`}>
          <ul className="nav-unorder-list">
            <Link to="/">
              <li
                className={activeLink === "/" ? "active" : "nav_item home"}
                onClick={handleClickNav}
              >
                Home
              </li>
            </Link>
            <Link to="/Features">
              <li
                className={activeLink === "/Features" ? "active" : "nav_item"}
                onClick={handleClickNav}
              >
                Features
              </li>
            </Link>
            <Link to="/AboutUs">
              <li
                className={activeLink === "/AboutUs" ? "active" : "nav_item"}
                onClick={handleClickNav}
              >
                About Us
              </li>
            </Link>
            <Link to="/Clientele">
              <li
                className={activeLink === "/Clientele" ? "active" : "nav_item"}
                onClick={handleClickNav}
              >
                Clientele
              </li>
            </Link>
            <Link to="/ContactUS">
              <li
                className={activeLink === "/ContactUS" ? "active" : "nav_item"}
                onClick={handleClickNav}
              >
                Contact Us
              </li>
            </Link>
          </ul>
        <div className="nav_button">
          <button onClick={handleClickNav}>
            <Link  to="https://calendly.com/mamata-makecrms/hrms-demo?month=2024-11" target="blank">HRMS Demo</Link>
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useState, useEffect, useRef } from "react";
// import Navbar from "./../../components/Navbar/Navbar";
// import "./Features.css";
// import Footer from "../../components/Footer/Footer";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { useGSAP } from "@gsap/react";
// import globeImg from "../../assets/globe FEATURES.png";
// import globe_2 from "../../assets/about-us-img3.png";
// import globe_3 from "../../assets/affilited-card-img.png";
// import globe_4 from "../../assets/blog-img-1.png";

// import shadowEffect from "../../assets/shadow image.png";
// import IconsMovement from "../../components/IconsMovement/IconsMovement";
// import PreFooter from "./../../components/PreFooter/PreFooter";
// import Scrolling from "../../components/ScrollToTop/Scroll";
// gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

// const Features = () => {
//   const layeryopheading = useRef();
//   useGSAP(() => {
//     const textAnimation = gsap.timeline();
//     // Animation for the heading in the first layer
//     textAnimation.from(layeryopheading.current, {
//       y: -170,
//       duration: 5,
//     });

//     // Animation for the first layer's paragraph
//     textAnimation.from(
//       ".layer-1-one-p",
//       {
//         opacity: 0,
//         y: -160, // Adjust the vertical movement as needed
//         duration: 3,
//       },
//       "-=3"
//     ); // Start this animation before the previous one ends

//     // Animation for the second layer heading and paragraph
//     gsap.from(".layerOne-right-h", {
//       y: -170,
//       duration: 5,
//     });

//     gsap.from(
//       ".layer-1-two-p",
//       {
//         opacity: 0,
//         y: -160,
//         duration: 3,
//       },
//       "-=3"
//     ); // Start this animation before the previous one ends

//     gsap.from(".layerOne-center-h", {
//       y: 170,
//       duration: 5,
//     });

//     // Animation for the middle section paragraph
//     gsap.from(
//       ".layer-1-three-p",
//       {
//         opacity: 0,
//         y: -160,
//         duration: 3,
//       },
//       "-=3"
//     );
//     const layoutTwoAnimation = gsap.timeline({
//       scrollTrigger: {
//         trigger: "#pagetwo",
//         start: "45% 95%",
//         end: "55% 60%",
//         scrub: true,
//         markers: false, // Uncomment to see debugging markers
//       },
//     });
//     layoutTwoAnimation.from(
//       ".layer-2-one-h",
//       {
//         opacity: 0,
//         y: -100,
//         duration: 3,
//       },
//       "layer-2"
//     );
//     layoutTwoAnimation.from(
//       ".layer-2-one-p",
//       {
//         opacity: 0,
//         y: -100,
//         duration: 3,
//       },
//       "layer-2"
//     );
//     layoutTwoAnimation.from(
//       ".layer-2-two-h",
//       {
//         opacity: 0,
//         y: -300,
//         duration: 3,
//       },
//       "layer-2"
//     );
//     layoutTwoAnimation.from(
//       ".layer-2-two-p",
//       {
//         opacity: 0,
//         y: -300,
//         duration: 3,
//       },
//       "layer-2"
//     );
//     layoutTwoAnimation.from(
//       ".layer-2-three-h, .layer-2-three-p",
//       {
//         opacity: 0,
//         y: -300,
//         duration: 3,
//       },
//       "layer-2"
//     );
//     const layoutThreeAnimation = gsap.timeline({
//       scrollTrigger: {
//         trigger: "#pagethree",
//         start: "63% 95%",
//         end: "65% 70%",
//         scrub: true,
//         markers: false, // Uncomment to see debugging markers
//       },
//     });
//     layoutThreeAnimation.from(
//       ".layer3-top-h, .layer3-top-p",
//       {
//         opacity: 0,
//         y: -300,
//         duration: 3,
//       },
//       "layer-3"
//     );

//     layoutThreeAnimation.from(
//       ".layer3-top-2-h, .layer3-top-2-p",
//       {
//         opacity: 0,
//         y: -300,
//         duration: 3,
//       },
//       "layer-3"
//     );
//     layoutThreeAnimation.from(
//       ".layer-3-bottom-h, .layer-3-bottom-p",
//       {
//         opacity: 0,
//         x: -400,
//         duration: 3,
//       },
//       "layer-3"
//     );
//     // layer4 animation text code
//     const layoutFourAnimation = gsap.timeline({
//       scrollTrigger: {
//         trigger: "#pagefour",
//         start: "50% 95%",
//         end: "65% 70%",
//         scrub: true,
//         markers: false, // Uncomment to see debugging markers
//       },
//     });
//     layoutFourAnimation.from(
//       ".layer4-top-1-h, .layer4-top-1-p",
//       {
//         opacity: 0,
//         y: -300,
//         duration: 3,
//       },
//       "layer-3"
//     );
//     layoutFourAnimation.from(
//       ".layer4-left-2-h, .layer4-left-2-p, .layer4-rigth-3-h, .layer4-rigth-4-p",
//       {
//         opacity: 0,
//         y: -450,
//         duration: 3,
//       },
//       "layer-3"
//     );
//   });

//   useEffect(() => {
//     const screenWidth = window.innerWidth;
//     const isMobile = screenWidth <= 425; // Define mobile threshold
  
//     // Set the initial position of the globe based on whether it's mobile or desktop
//     gsap.set("#globe", {
//       x: isMobile ? "0vw" : "0vw", // Adjust x position for mobile
//       y: isMobile ? "0vh" : "0vh", // Adjust y position for desktop
//       opacity: 1,
//     });
  
//     gsap.set("#globe_2", { opacity: 0 });
//     gsap.set("#globe_3", { opacity: 0 });
//     // gsap.set("#globe_4", { opacity: 0 });
  
//     // Page two animation
//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: "#pagetwo",
//         start: "0% 95%",
//         end: "50% 60%",
//         scrub: true,
//         markers: false, // Uncomment to see debugging markers
//       },
//     })
//     tl.to("#globe", {
//       motionPath: {
//         path: isMobile
//           ? [
//               { x: "0vw", y: "0vh" },
//               { x: "10vw", y: "5vh" },
//               { x: "15vw", y: "40vh" },
//               { x: "0vw", y: "60vh" },
//             ]
//           : [
//               { x: "0vw", y: "0vh" },
//               { x: "10vw", y: "0vh" },
//               { x: "30vw", y: "70vh" },
//               { x: "0vw", y: "120vh" },
//             ],
//         curviness: 3,
//         autoRotate: false,
//       },
//       duration: 3,
//       ease: "power1.inOut",
//     })
//       .to("#globe", { opacity: 0 }, "-=0.5") // Fade out globe
//       .to("#globe_2", { opacity: 1 }, "-=0.5") // Fade in globe_2
//       .to("#globe_3", { opacity: 0 }, "-=0.5"); // Fade in globe_2
  
//     // Page three animation
//     const tl3 = gsap.timeline({
//       scrollTrigger: {
//         trigger: "#pagethree",
//         start: "0% 95%",
//         end: "50% 60%",
//         scrub: true,
//         markers: false, // Uncomment to see debugging markers
//       },
//     });
  
//     tl3.to("#globe_2", {
//       motionPath: {
//         path: isMobile
//           ? [
//               { x: "0vw", y: "60vh" },
//               { x: "-5vw", y: "70vh" },
//               { x: "-15vw", y: "90vh" },
//               { x: "0vw", y: "100vh" },
//             ]
//           : [
//               { x: "0vw", y: "120vh" },
//               { x: "-10vw", y: "120vh" },
//               { x: "-30vw", y: "170vh" },
//               { x: "0vw", y: "200vh" },
//             ],
//         curviness: 3,
//         autoRotate: false,
//       },
//       duration: 3,
//       ease: "power1.inOut",
//     })
  

//     .to("#globe", { opacity: 0 }, "-=0.5") // Fade in globe
//     .to("#globe_2", { opacity: 0 }, "-=0.5") // Fade out globe_2
//     .to("#globe_3", { opacity: 1 }, "-=0.5") // Fade out globe_2
//     // Page four animation
//     const tl4 = gsap.timeline({
//       scrollTrigger: {
//         trigger: "#pagefour",
//         start: "0% 95%",
//         end: "50% 60%",
//         scrub: true,
//         markers: false, // Uncomment to see debugging markers
//       },
//     });
  
//     tl4.to("#globe_3", {
//       motionPath: {
//         path: isMobile
//           ? [
//               { x: "0vw", y: "50vh" },
//               { x: "5vw", y: "110vh" },
//               { x: "15vw", y: "140vh" },
//               { x: "0vw", y: "100vh" },
//             ]
//           : [
//               { x: "0vw", y: "200vh" },
//               { x: "10vw", y: "200vh" },
//               { x: "30vw", y: "280vh" },
//               { x: "0vw", y: "320vh" },
//             ],
//         curviness: 3,
//         autoRotate: false,
//       },
//       duration: 3,
//       ease: "power1.inOut",
//     })
//       .to("#globe", { opacity: 0 }, "-=0.5") // Fade in globe
//       .to("#globe_2", { opacity: 0 }, "-=0.5") // Fade out globe_2
//       .to("#globe_3", { opacity: 1 }, "-=0.5") // Fade out globe_2
//   }, []);
  

//   // useEffect(() => {
//   //   const screenWidth = window.innerWidth;
//   //   const screenHeight = window.innerHeight;
//   //   // Page two animation
//   //   const tl = gsap.timeline({
//   //     scrollTrigger: {
//   //       trigger: "#pagetwo",
//   //       start: "0% 95%",
//   //       end: "50% 60%",
//   //       scrub: true,
//   //       markers: false, // Uncomment to see debugging markers
//   //     },
//   //   });

//   //   tl.to("#globe", {
//   //     motionPath: {
//   //       path: [
//   //         { x: "0vw", y: "0vh" }, // Start from where the second page ended
//   //         { x: "10vw", y: "0vh" }, // Move down-right further in vw/vh units
//   //         { x: "30vw", y: "70vh" }, // Create a different curve for the second page
//   //         { x: "0vw", y: "120vh" }, // End of curve for page three
//   //         // { x: 0, y: 0 }, // Start from where the second page ended
//   //         // { x: 100, y: 0 }, // Move down-right further
//   //         // { x: 300, y: 600 }, // Create a different curve for the second page
//   //         // { x: 0, y: 750 }, // End of curve for page three
//   //       ],
//   //       curviness: 3,
//   //       autoRotate: false,
//   //     },
//   //     duration: 3,
//   //     ease: "power1.inOut",
//   //   });

//   //   // Page three animation
//   //   const tl3 = gsap.timeline({
//   //     scrollTrigger: {
//   //       trigger: "#pagethree",
//   //       start: "0% 95%",
//   //       end: "50% 60%",
//   //       scrub: true,
//   //       markers: false, // Uncomment to see debugging markers
//   //     },
//   //   });

//   //   tl3.to("#globe", {
//   //     motionPath: {
//   //       path: [
//   //         { x: "0vw", y: "120vh" }, // Start from where the second page ended
//   //         { x: "-10vw", y: "120vh" }, // Move down-right further in vw/vh units
//   //         { x: "-30vw", y: "170vh" }, // Create a different curve for the second page
//   //         { x: "0vw", y: "200vh" }, // End of curve for page three
//   //         // { x: 0, y: 750 }, // Start from where the second page ended
//   //         // { x: -100, y: 750 }, // Move down-right further
//   //         // { x: -300, y: 1150 }, // Create a different curve for the second page
//   //         // { x: 0, y: 1250 }, // End of curve for page three
//   //       ],
//   //       curviness: 3,
//   //       autoRotate: false,
//   //     },
//   //     duration: 3,
//   //     ease: "power1.inOut",
//   //   });

//   //   // Page four animation
//   //   const tl4 = gsap.timeline({
//   //     scrollTrigger: {
//   //       trigger: "#pagefour",
//   //       start: "0% 95%",
//   //       end: "50% 60%",
//   //       scrub: true,
//   //       markers: false, // Uncomment to see debugging markers
//   //     },
//   //   });

//   //   tl4.to("#globe", {
//   //     motionPath: {
//   //       path: [
//   //         { x: "0vw", y: "200vh" }, // Start from where the second page ended
//   //         { x: "10vw", y: "200vh" }, // Move down-right further in vw/vh units
//   //         { x: "30vw", y: "280vh" }, // Create a different curve for the second page
//   //         { x: "0vw", y: "320vh" }, // End of curve for page three
//   //         // { x: 0, y: 1250 }, // Start from where the second page ended
//   //         // { x: 100, y: 1300 }, // Move down-right further
//   //         // { x: 300, y: 1900 }, // Create a different curve for the second page
//   //         // { x: 0, y: 2030 }, // End of curve for page three
//   //       ],
//   //       curviness: 3,
//   //       autoRotate: false,
//   //     },
//   //     duration: 3,
//   //     ease: "power1.inOut",
//   //   });
//   // }, []);

//   // useEffect(() => {
//   //   const screenWidth = window.innerWidth;
//   //   const isMobile = screenWidth <= 425; // Define mobile threshold

//   //   // Set the initial position of the globe based on whether it's mobile or desktop
//   //   gsap.set("#globe", {
//   //     x: isMobile ? "0vw" : "0vw", // Adjust x position for mobile
//   //     y: isMobile ? "0vh" : "0vh", // Adjust y position for mobile if needed
//   //     opacity: 1,
//   //   });

//   //   gsap.set("#globe_2", { opacity: 0 });

//   //   // Page two animation
//   //   const tl = gsap.timeline({
//   //     scrollTrigger: {
//   //       trigger: "#pagetwo",
//   //       start: "0% 95%",
//   //       end: "50% 60%",
//   //       scrub: true,
//   //       markers: false, // Uncomment to see debugging markers
//   //     },
//   //   });

//   //   tl.to("#globe", {
//   //     motionPath: {
//   //       path: isMobile
//   //         ? [
//   //             { x: "0vw", y: "0vh" },
//   //             { x: "10vw", y: "5vh" },
//   //             { x: "15vw", y: "40vh" },
//   //             { x: "0vw", y: "60vh" },
//   //           ]
//   //         : [
//   //             { x: "0vw", y: "0vh" },
//   //             { x: "10vw", y: "0vh" },
//   //             { x: "30vw", y: "70vh" },
//   //             { x: "0vw", y: "120vh" },
//   //           ],
//   //       curviness: 3,
//   //       autoRotate: false,
//   //     },
//   //     duration: 3,
//   //     ease: "power1.inOut",
//   //   })

//   //   .to("#globe", {
//   //     opacity: 0,
//   //   })

//   //   .to("#globe_2", {
//   //     opacity: 1,
//   //   });
//   //   // Page three animation
//   //   const tl3 = gsap.timeline({
//   //     scrollTrigger: {
//   //       trigger: "#pagethree",
//   //       start: "0% 95%",
//   //       end: "50% 60%",
//   //       scrub: true,
//   //       markers: false, // Uncomment to see debugging markers
//   //     },
//   //   });

//   //   tl3.to("#globe_2", {
//   //     motionPath: {
//   //       path: isMobile
//   //         ? [
//   //             { x: "0vw", y: "60vh" },
//   //             { x: "-5vw", y: "70vh" },
//   //             { x: "-15vw", y: "90vh" },
//   //             { x: "0vw", y: "100vh" },
//   //           ]
//   //         : [
//   //             { x: "0vw", y: "120vh" },
//   //             { x: "-10vw", y: "120vh" },
//   //             { x: "-30vw", y: "170vh" },
//   //             { x: "0vw", y: "200vh" },
//   //           ],
//   //       curviness: 3,
//   //       autoRotate: false,
//   //     },
//   //     duration: 3,
//   //     ease: "power1.inOut",
    
//   //   })

//   //   .to("#globe", {opacity: 1})

//   //   .to("#globe_2", {opacity: 0})

//   //   // Page four animation
//   //   const tl4 = gsap.timeline({
//   //     scrollTrigger: {
//   //       trigger: "#pagefour",
//   //       start: "0% 95%",
//   //       end: "50% 60%",
//   //       scrub: true,
//   //       markers: false, // Uncomment to see debugging markers
//   //     },
//   //   });

//   //   tl4.to("#globe", {
//   //     motionPath: {
//   //       path: isMobile
//   //         ? [
//   //             { x: "0vw", y: "50vh" },
//   //             { x: "5vw", y: "110vh" },
//   //             { x: "15vw", y: "140vh" },
//   //             { x: "0vw", y: "100vh" },
//   //           ]
//   //         : [
//   //             { x: "0vw", y: "200vh" },
//   //             { x: "10vw", y: "200vh" },
//   //             { x: "30vw", y: "280vh" },
//   //             { x: "0vw", y: "320vh" },
//   //           ],
//   //       curviness: 3,
//   //       autoRotate: false,
//   //     },
//   //     duration: 3,
//   //     ease: "power1.inOut",
     
//   //   })
//   //   .to("#globe", {opacity: 1})
//   //   .to("#globe_2", {opacity: 0})
//   // }, []);

//   return (
//     <div className="features">
//       <Navbar />
//       <div className="hrms-page-container">
//         {/* Your existing pages go here */}
//         <div className="page first-page">
//           <div className="top-section">
//             <div className="title-top-feature-page">
//               <h3
//                 ref={layeryopheading}
//                 className="features-1st-page-title-1 layer-one-left-heading"
//               >
//                 Employee Management System
//               </h3>
//               <p className="layer-1-one-p para-color ">
//                 Streamline employee data management, tracking performance, and
//                 simplifying HR processes.
//               </p>
//             </div>
//             <div className="img-container"></div>
//             <img src={globeImg} alt="World Globe" id="globe" />
//             <img src={globe_2} alt="World Globe" id="globe_2" />
//             <img src={globe_3} alt="World Globe" id="globe_3" />
//             {/* <img src={globe_4} alt="World Globe" id="globe_4" /> */}
//             <div className="title-top-feature-page">
//               <h3 className="features-1st-page-title-2  layerOne-right-h">
//                 Payroll Management
//               </h3>
//               <p className="layer-1-two-p para-color ">
//                 Automate payroll processing, tax calculations, and salary
//                 disbursement with seamless precision.
//               </p>
//             </div>
//           </div>
//           <div className="middle-section">
//             <h3 className="layerOne-center-h">Attendance Management</h3>
//             <p className="layer-1-three-p para-color ">
//               Monitor employee attendance, leave, and work hours effortlessly
//               for accurate tracking and reporting.
//             </p>
//           </div>
//         </div>
//         <div className="styled-hr"></div>

//         {/* Second Page */}
//         <div className="page second-page " id="pagetwo">
//           <div className="middle-section-bottom">
//             <h3 className="layer-2-one-h">Employee Self Service</h3>
//             <p className="layer-2-one-p para-color ">
//               Empower employees to access personal data, policies, and manage
//               their profiles independently.
//             </p>
//           </div>
//           <div className="bottom-section">
//             <div className="title-bottom">
//               <h3 className="layer-2-two-h">Work Report Management</h3>
//               <p className="layer-2-two-p para-color">
//                 Simplify work report submission, tracking, and performance
//                 reviews, integrated with project management.
//               </p>
//             </div>
//             <div className="img-container"></div>
//             {/* <img src={globeImg} alt="World Globe" className="globe-img" /> */}
//             <div className="title-bottom-second-layer">
//               <h3 className="layer-2-three-h">Leave Management</h3>
//               <p className="layer-2-three-p para-color">
//                 Efficiently manage leave requests, approvals, and balances with
//                 a user-friendly, automated system.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="styled-hr"></div>

//         {/* Third Page */}
//         <div className="page first-page" id="pagethree">
//           <div className="top-section">
//             <div className="title-third-page-feature-page">
//               <h3 className="features-1st-page-title-1 layer3-top-h ">
//                 Training & Learning
//               </h3>
//               <p className="layer3-top-p para-color">
//                 Facilitate employee development through customizable training
//                 programs and learning modules.
//               </p>
//             </div>
//             <div className="img-container"></div>
//             {/* <img src={globeImg} alt="World Globe" className="globe-img-3" /> */}
//             <div className="title-third-page-feature-page">
//               <h3 className="features-1st-page-title-2 layer3-top-2-h">
//                 Organization Structure
//               </h3>
//               <p className="layer3-top-2-p para-color">
//                 Visualize and manage your company's hierarchy with an intuitive
//                 organizational structure tool.
//               </p>
//             </div>
//           </div>
//           <div className="middle-section-third-page">
//             <h3 className="layer-3-bottom-h">Task Management</h3>
//             <p className="layer-3-bottom-p para-color">
//               Streamline task assignment, tracking, and completion for enhanced
//               productivity and collaboration.
//             </p>
//           </div>
//         </div>
//         <div className="styled-hr"></div>

//         {/* Fourth Page */}
//         <div className="page second-page" id="pagefour">
//           <div className="middle-section-bottom">
//             <h3 className="layer4-top-1-h">Employee Self Service</h3>
//             <p className="layer4-top-1-p para-color">
//               Empower employees to access personal data, policies, and manage
//               their profiles independently.
//             </p>
//           </div>
//           <div className="bottom-section">
//             <div className="title-bottom">
//               <h3 className="layer4-left-2-h">Work Report Management</h3>
//               <p className="layer4-left-2-p para-color">
//                 Simplify work report submission, tracking, and performance
//                 reviews, integrated with project management.
//               </p>
//             </div>
//             <div className="img-container"></div>
//             {/* <img src={globeImg} alt="World Globe" className="globe-img" /> */}
//             <div className="title-bottom-second-layer">
//               <h3 className="layer4-rigth-3-h">Leave Management</h3>
//               <p className="layer4-rigth-4-p para-color">
//                 Efficiently manage leave requests, approvals, and balances with
//                 a user-friendly, automated system.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <IconsMovement />
//       {/* <div className="last-container-features">
//         <h1 className="last-container-heading-features">
//           Reserve A Time For A Demo
//         </h1>
//         <p className="last-container-para-features para-color">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis
//           egestas ante, sed viverra nunc tincidunt nec eleifend et tincidunt.
//           Business-to-business metrics analytics value proposition funding angel
//           investor
//         </p>
//         <button className="last-container-button">Get A Demo</button>
//       </div> */}
//       <PreFooter />
//       <Footer />
//       <Scrolling />
//       {/* <div className="styled-hr"></div> */}
//     </div>
//   );
// };

// export default Features;





import React, { useState, useEffect, useRef } from "react";
import Navbar from "./../../components/Navbar/Navbar";
import "./Features.css";
import Footer from "../../components/Footer/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { useGSAP } from "@gsap/react";
import globeImg from "../../assets/future_image_2.png";
import globe_2 from "../../assets/future_img_1.png";
import globe_3 from "../../assets/future_image_3.png";
import globe_4 from "../../assets/future_image_4.png";
import shadowEffect from "../../assets/shadow image.png";
import IconsMovement from "../../components/IconsMovement/IconsMovement";
import PreFooter from "./../../components/PreFooter/PreFooter";
import Scrolling from "../../components/ScrollToTop/Scroll";
import FutureAnimation from "../../components/Futurecomponent/futureanimation";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const Features = () => {
  const layeryopheading = useRef();
  useGSAP(() => {
    const textAnimation = gsap.timeline();
   // Animation for the heading in the first layer
   textAnimation.from(layeryopheading.current, {
    y: -170,
    duration: 5,
});

// Animation for the first layer's paragraph
textAnimation.from(".layer-1-one-p", {
    opacity: 0,
    y: -160, // Adjust the vertical movement as needed
    duration: 3,
}, "-=3"); // Start this animation before the previous one ends

// Animation for the second layer heading and paragraph
gsap.from(".layerOne-right-h", {
    y: -170,
    duration: 5,
});

gsap.from(".layer-1-two-p", {
    opacity: 0,
    y: -160,
    duration: 2,
}, "-=3"); // Start this animation before the previous one ends

gsap.from(".layerOne-center-h", {
    y: 170,
    duration: 5,
});

// Animation for the middle section paragraph
gsap.from(".layer-1-three-p", {
    opacity: 0,
    y: -160,
    duration: 2,
}, "-=3");
    const layoutTwoAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagetwo",
        start: "45% 95%",
        end: "55% 60%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
    layoutTwoAnimation.from(
      ".layer-2-one-h",
      {
        opacity: 0,
        y: -100,
        duration: 2,
      },
      "layer-2"
    );
    layoutTwoAnimation.from(
      ".layer-2-one-p",
      {
        opacity: 0,
        y: -100,
        duration: 2,
      },
      "layer-2"
    );
    layoutTwoAnimation.from(
      ".layer-2-two-h",
      {
        opacity: 0,
        y: -300,
        duration: 2,
      },
      "layer-2"
    );
    layoutTwoAnimation.from(
      ".layer-2-two-p",
      {
        opacity: 0,
        y: -300,
        duration: 2,
      },
      "layer-2"
    );
    layoutTwoAnimation.from(
      ".layer-2-three-h, .layer-2-three-p",
      {
        opacity: 0,
        y: -300,
        duration: 2,
      },
      "layer-2"
    );
    const layoutThreeAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagethree",
        start: "63% 95%",
        end: "65% 70%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
    layoutThreeAnimation.from(
      ".layer3-top-h, .layer3-top-p",
      {
        opacity: 0,
        y: -300,
        duration: 2,
      },
      "layer-3"
    );

    layoutThreeAnimation.from(
      ".layer3-top-2-h, .layer3-top-2-p",
      {
        opacity: 0,
        y: -300,
        duration: 2,
      },
      "layer-3"
    );
    layoutThreeAnimation.from(
      ".layer-3-bottom-h, .layer-3-bottom-p",
      {
        opacity: 0,
        x: -400,
        duration: 2,
      },
      "layer-3"
    );
    // layer4 animation text code
    const layoutFourAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagefour",
        start: "50% 95%",
        end: "65% 70%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
    layoutFourAnimation.from(
      ".layer4-top-1-h, .layer4-top-1-p",
      {
        opacity: 0,
        y: -300,
        duration: 2,
      },
      "layer-3"
    );
    layoutFourAnimation.from(
      ".layer4-left-2-h, .layer4-left-2-p, .layer4-rigth-3-h, .layer4-rigth-4-p",
      {
        opacity: 0,
        y: -450,
        duration: 2,
      },
      "layer-3"
    );
  });

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth <= 425; // Define mobile threshold
  
    // Set the initial position of the globe based on whether it's mobile or desktop
    gsap.set(["#globe_2"], {
      x: isMobile ? "0vw" : "0vw", // Adjust x position for mobile
      y: isMobile ? "0vh" : "0vh",    // Adjust y position for mobile if needed
      opacity:1,
    });
  
   


    gsap.set(["#globe"], {
      x: isMobile ? "0vw" : "0vw", // Adjust x position for mobile
      y: isMobile ? "70vh" : "130vh",    // Adjust y position for mobile if needed
      opacity:1,
      
    });


    
    gsap.set(["#globe_3"], {
      x: isMobile ? "0vw" : "0vw", // Adjust x position for mobile
      y: isMobile ? "50vh" : "200vh",    // Adjust y position for mobile if needed
      opacity:1,
      
    });
    gsap.set(["#globe_4"], {
      x: isMobile ? "0vw" : "0vw", // Adjust x position for mobile
      y: isMobile ? "50vh" : "200vh",    // Adjust y position for mobile if needed
      opacity:1,
      
    });

  
    // Page two animation
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagetwo",
        start: "0% 95%",
        end: "50% 60%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
  
    tl.to(["#globe_2"], {
      motionPath: {
        path: isMobile
          ? [
              { x: "0vw", y: "0vh" },
              { x: "10vw", y: "5vh" },
              { x: "15vw", y: "40vh" },
              { x: "0vw", y: "60vh" },
            ]
          : [
              { x: "0vw", y: "0vh" }, 
              { x: "10vw", y: "0vh" }, 
              { x: "30vw", y: "70vh" }, 
              { x: "0vw", y: "120vh" },
            ],
        curviness: 3,
        autoRotate: false,
      },
      duration: 3,
      ease: "power1.inOut",
     
    }).to(["#globe_2"], {opacity: 0 }, ">-0.5").to(["#globe_4"], {opacity: 0 }, ">-0.5")
   
  
   


    // Page three animation
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagethree",
        start: "0% 95%",
        end: "50% 60%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
  
    tl3.to("#globe", {
      motionPath: {
        path: isMobile
          ? [
              { x: "0vw", y: "60vh" },
              { x: "-5vw", y: "70vh" },
              { x: "-15vw", y: "90vh" },
              { x: "0vw", y: "100vh" },
            ]
          : [
              { x: "0vw", y: "120vh" }, 
              { x: "-10vw", y: "120vh" }, 
              { x: "-30vw", y: "170vh" }, 
              { x: "0vw", y: "200vh" },
            ],
        curviness: 3,
        autoRotate: false,
      },
      duration: 3,
      ease: "power1.inOut",
    }).to("#globe", { opacity: 0 }, ">-0.5").to(["#globe_4"], {opacity: 0 }, ">-0.5")
  


 
 

    // Page four animation
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagefour",
        start: "0% 95%",
        end: "50% 60%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
  
    tl4.to("#globe_3", {
      motionPath: {
        path: isMobile
          ? [
              { x: "0vw", y: "50vh" },
              { x: "5vw", y: "110vh" },
              { x: "15vw", y: "140vh" },
              { x: "0vw", y: "100vh" },
            ]
          : [
              { x: "0vw", y: "200vh" }, 
              { x: "10vw", y: "200vh" }, 
              { x: "30vw", y: "280vh" }, 
              { x: "0vw", y: "320vh" },
            ],
        curviness: 3,
        autoRotate: false,
      },
      duration: 3,
      ease: "power1.inOut",
    }).to("#globe_3", { opacity: 0 }, ">-0.5").to(["#globe_4"], {opacity: 0 }, ">-0.5")


    // Page fifth animation


    const tl5 = gsap.timeline({
      scrollTrigger: {
        trigger: "#pagefour",
        // start: "0% 95%",
        end: "50% 60%",
        scrub: true,
        markers: false, // Uncomment to see debugging markers
      },
    });
  
    tl4.to("#globe_4", {
      motionPath: {
        path: isMobile
          ? [
              { x: "0vw", y: "50vh" },
              { x: "5vw", y: "110vh" },
              { x: "15vw", y: "140vh" },
              { x: "0vw", y: "100vh" },
            ]
          : [
              { x: "0vw", y: "200vh" }, 
              { x: "10vw", y: "200vh" }, 
              { x: "30vw", y: "280vh" }, 
              { x: "0vw", y: "320vh" },
            ],
        curviness: 3,
        autoRotate: false,
      },
      duration: 3,
      ease: "power1.inOut",
    }).to("#globe_4", { opacity: 1 }, ">-0.5");




    
  }, []);



  // useEffect(() => {
  //   const screenWidth = window.innerWidth;
  //   const isMobile = screenWidth <= 425; // Define mobile threshold
    
  //   if (isMobile) {
  //     gsap.set(["#globe_2", "#globe", "#globe_3", "#globe_4"], {
  //       x: "0vw",
  //       y: "0vh",
  //       opacity: 1,
  //     });
  
  //     gsap.timeline({
  //       scrollTrigger: {
  //         trigger: "#pagetwo",
  //         start: "0% 95%",
  //         end: "50% 60%",
  //         scrub: true,
  //         markers: false,
  //       },
  //     })
  //       .to("#globe_2", {
  //         motionPath: [
  //           { x: "0vw", y: "0vh" },
  //           { x: "10vw", y: "5vh" },
  //           { x: "15vw", y: "40vh" },
  //           { x: "0vw", y: "60vh" },
  //         ],
  //         curviness: 3,
  //         duration: 3,
  //         ease: "power1.inOut",
  //       })
  //       .to("#globe_2", { opacity: 0 }, ">-0.5")
  //       .to("#globe_4", { opacity: 0 }, ">-0.5");
  
  //     gsap.timeline({
  //       scrollTrigger: {
  //         trigger: "#pagethree",
  //         start: "0% 95%",
  //         end: "50% 60%",
  //         scrub: true,
  //         markers: false,
  //       },
  //     })
  //       .to("#globe", {
  //         motionPath: [
  //           { x: "0vw", y: "60vh" },
  //           { x: "-5vw", y: "70vh" },
  //           { x: "-15vw", y: "90vh" },
  //           { x: "0vw", y: "100vh" },
  //         ],
  //         curviness: 3,
  //         duration: 3,
  //         ease: "power1.inOut",
  //       })
  //       .to("#globe", { opacity: 0 }, ">-0.5")
  //       .to("#globe_4", { opacity: 0 }, ">-0.5");
  
  //     gsap.timeline({
  //       scrollTrigger: {
  //         trigger: "#pagefour",
  //         start: "0% 95%",
  //         end: "50% 60%",
  //         scrub: true,
  //         markers: false,
  //       },
  //     })
  //       .to("#globe_3", {
  //         motionPath: [
  //           { x: "0vw", y: "50vh" },
  //           { x: "5vw", y: "110vh" },
  //           { x: "15vw", y: "140vh" },
  //           { x: "0vw", y: "100vh" },
  //         ],
  //         curviness: 3,
  //         duration: 3,
  //         ease: "power1.inOut",
  //       })
  //       .to("#globe_3", { opacity: 0 }, ">-0.5")
  //       .to("#globe_4", { opacity: 0 }, ">-0.5");
  
  //     gsap.timeline({
  //       scrollTrigger: {
  //         trigger: "#pagefour",
  //         end: "50% 60%",
  //         scrub: true,
  //         markers: false,
  //       },
  //     }).to("#globe_4", {
  //       motionPath: [
  //         { x: "0vw", y: "50vh" },
  //         { x: "5vw", y: "110vh" },
  //         { x: "15vw", y: "140vh" },
  //         { x: "0vw", y: "100vh" },
  //       ],
  //       curviness: 3,
  //       duration: 3,
  //       ease: "power1.inOut",
  //     }).to("#globe_4", { opacity: 1 }, ">-0.5");
  //   }
  // }, []);
  
  

  
  return (
    <div className="features">
      <Navbar />
      <div className="hrms-page-container">
        {/* Your existing pages go here */}
        <div className="page first-page">
          <div className="top-section">
            <div className="title-top-feature-page">
              <h3
                ref={layeryopheading}
                className="features-1st-page-title-1 layer-one-left-heading"
              >
                Employee Management System
              </h3>
              <p  className="layer-1-one-p para-color ">Streamline employee data management, tracking performance, and simplifying HR processes.</p>
            </div>
            <div className="img-container"></div>
           
            <img src={globe_2} alt="World Globe" id="globe_2" />
            <img src={globeImg} alt="World Globe" id="globe" />
            <img src={globe_3} alt="World Globe" id="globe_3" />
           <img src={globe_4} alt="World Globe" id="globe_4" /> 

            <div className="title-top-feature-page">
              <h3 className="features-1st-page-title-2  layerOne-right-h">
                Payroll Management
              </h3>
              <p  className="layer-1-two-p para-color ">Automate payroll processing, tax calculations, and salary disbursement with seamless precision.</p>
            </div>
          </div>
          <div className="middle-section">
            <h3 className="layerOne-center-h">Attendance Management</h3>
            <p  className="layer-1-three-p para-color ">Monitor employee attendance, leave, and work hours effortlessly for accurate tracking and reporting.</p>
          </div>
        </div>
        <div className="styled-hr"></div>

        {/* Second Page */}
        <div className="page second-page " id="pagetwo">
          <div className="middle-section-bottom">
            <h3 className="layer-2-one-h">Employee Self Service</h3>
            <p className="layer-2-one-p para-color ">
              Empower employees to access personal data, policies, and manage
              their profiles independently.
            </p>
          </div>
          <div className="bottom-section">
            <div className="title-bottom">
              <h3 className="layer-2-two-h">Work Report Management</h3>
              <p className="layer-2-two-p para-color">
                Simplify work report submission, tracking, and performance
                reviews, integrated with project management.
              </p>
            </div>
            <div className="img-container"></div>
            {/* <img src={globeImg} alt="World Globe" className="globe-img" /> */}
            <div className="title-bottom-second-layer">
              <h3 className="layer-2-three-h">Leave Management</h3>
              <p className="layer-2-three-p para-color">
                Efficiently manage leave requests, approvals, and balances with
                a user-friendly, automated system.
              </p>
            </div>
          </div>
        </div>
        <div className="styled-hr"></div>

        {/* Third Page */}
        <div className="page first-page" id="pagethree">
          <div className="top-section">
            <div className="title-third-page-feature-page">
              <h3 className="features-1st-page-title-1 layer3-top-h ">
                Training & Learning
              </h3>
              <p className="layer3-top-p para-color">
                Facilitate employee development through customizable training
                programs and learning modules.
              </p>
            </div>
            <div className="img-container"></div>
            {/* <img src={globeImg} alt="World Globe" className="globe-img-3" /> */}
            <div className="title-third-page-feature-page">
              <h3 className="features-1st-page-title-2 layer3-top-2-h">
                Organization Structure
              </h3>
              <p className="layer3-top-2-p para-color">
                Visualize and manage your company's hierarchy with an intuitive
                organizational structure tool.
              </p>
            </div>
          </div>
          <div className="middle-section-third-page">
            <h3 className="layer-3-bottom-h">Task Management</h3>
            <p className="layer-3-bottom-p para-color">
              Streamline task assignment, tracking, and completion for enhanced
              productivity and collaboration.
            </p>
          </div>
        </div>
        <div className="styled-hr"></div>

        {/* Fourth Page */}
        <div className="page second-page" id="pagefour">
          <div className="middle-section-bottom">
            <h3 className="layer4-top-1-h">Employee Self Service</h3>
            <p className="layer4-top-1-p para-color">
              Empower employees to access personal data, policies, and manage
              their profiles independently.
            </p>
          </div>
          <div className="bottom-section">
            <div className="title-bottom">
              <h3 className="layer4-left-2-h">Work Report Management</h3>
              <p className="layer4-left-2-p para-color">
                Simplify work report submission, tracking, and performance
                reviews, integrated with project management.
              </p>
            </div>
            <div className="img-container"></div>
            {/* <img src={globeImg} alt="World Globe" className="globe-img" /> */}
            <div className="title-bottom-second-layer">
              <h3 className="layer4-rigth-3-h">Leave Management</h3>
              <p className="layer4-rigth-4-p para-color">
                Efficiently manage leave requests, approvals, and balances with
                a user-friendly, automated system.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FutureAnimation/>
      <IconsMovement />
      {/* <div className="last-container-features">
        <h1 className="last-container-heading-features">
          Reserve A Time For A Demo
        </h1>
        <p className="last-container-para-features para-color">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis
          egestas ante, sed viverra nunc tincidunt nec eleifend et tincidunt.
          Business-to-business metrics analytics value proposition funding angel
          investor
        </p>
        <button className="last-container-button">Get A Demo</button>
      </div> */}
      <PreFooter />
      <Footer />
      <Scrolling/>
      {/* <div className="styled-hr"></div> */}
    </div>
  );
};

export default Features;